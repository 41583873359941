import { Form, useSearchParams } from "react-router-dom";
import { ICONS, Input } from "ui-kit";

import { AddUserButton, AddUserForm } from "./AddUserForm";
import styles from "./RolesHeader.module.scss";

export const RolesHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get("q");
  const changeSearchParams = (q: string, isFirstSearch: boolean) =>
    setSearchParams((prev) => (prev.set("q", q), prev), {
      replace: !isFirstSearch,
    });

  return (
    <header className={styles.header}>
      <Form role="search" className={styles.searchForm}>
        <Input
          prefix={<ICONS.Search />}
          type="search"
          role="searchbox"
          defaultValue={searchValue || ""}
          onChange={(e) =>
            changeSearchParams(e.currentTarget.value, searchValue === null)
          }
        />
      </Form>
      <AddUserButton>
        <AddUserForm fullName={searchValue || undefined} />
      </AddUserButton>
    </header>
  );
};
