import { TaskMessages } from "containers/Dialogs/Messages/Messages";
import { useOutletContext } from "react-router-dom";

import { BookingDetailsContext } from "../DetailsHeader/BookingDetailsHeader";
import styles from "./BookingChat.module.scss";

export const BookingChat = () => {
  const booking = useOutletContext<BookingDetailsContext>();
  return (
    <section className={styles.bookingChatContainer}>
      <TaskMessages
        customerId={String(booking.client?.client_id)}
        taskId={booking.feed_id}
        className={styles.chat}
      />
    </section>
  );
};
