import {
  GuestFormContainer,
  GuestHistory,
  GuestHistoryError,
  GuestInfo,
  GuestInfoError,
  GuestNotes,
  GuestNotesError,
} from "components/GuestInfo";
import { Layout } from "components/Layout";
import { HallContainer } from "components/hall-scheme/redux/HallContainer";
import {
  Roles,
  RolesErrorBoundary,
  rolesAction,
  rolesLoader,
} from "components/settings/sub-renders/Roles";
import { config } from "config";
import {
  Auth,
  action as loginAction,
  loader as loginLoader,
} from "containers/Auth";
import { BookingOrders } from "containers/BookingOrders";
import { Dashboard } from "containers/Dashboard";
import {
  BookingChat,
  BookingDetailsHeader,
  BookingHistory,
  BookingInfo,
  BookingSlotDetailsError,
  EmptyDetails,
  ManagementDetailsHeader,
  ManagementInfo,
  ManagementSlotDetailsError,
  bookingSlotLoader,
  managementSlotLoader,
} from "containers/Dashboard/SlotDetails";
import { Dialog, Dialogs } from "containers/Dialogs";
import { loader as dialogLoader } from "containers/Dialogs/Dialog/Dialog";
import { FeedList } from "containers/Dialogs/FeedList/FeedList";
import {
  EditRecordFromDialog,
  loader as editRecordLoader,
} from "containers/Dialogs/ReservationFromDialog/EditRecordFromDialog";
import { ReservationFromDialog } from "containers/Dialogs/ReservationFromDialog/ReservationFromDialog";
import { Guests, loader as guestsLoader } from "containers/Guests";
import { EmptyGuestDetails, GuestCard } from "containers/Guests/GuestCard";
import { HallScheme } from "containers/HallScheme";
import { RequestsGrid } from "containers/RequestsGrid";
import { ReservationForm } from "containers/ReservationForm";
import { ScheduleLanding } from "containers/ScheduleLanding";
import { Search } from "containers/Search";
import { Settings } from "containers/Settings";
import { createBrowserRouter, redirect } from "react-router-dom";

import { AppProvider } from "./providers/AppProvider";
import { RootProvider, rootLoader } from "./root";

export const router = createBrowserRouter([
  {
    path: "/",
    loader: rootLoader, //переместить ли?
    shouldRevalidate: ({ nextUrl, defaultShouldRevalidate }) =>
      defaultShouldRevalidate || nextUrl.pathname === "/", //ревалидация, если идем в компоненте ниже, но с url /,
    element: <RootProvider />,
    children: [
      {
        element: <AppProvider />,
        children: [
          {
            element: <Layout />,
            children: [
              {
                path: "/dashboard",
                element: <Dashboard />,
                children: [
                  { index: true, element: <EmptyDetails /> },
                  {
                    loader: bookingSlotLoader,
                    path: "booking/:slotId",
                    element: <BookingDetailsHeader />,
                    errorElement: <BookingSlotDetailsError />,
                    children: [
                      { index: true, loader: () => redirect("info") },
                      {
                        path: "info",
                        element: <BookingInfo />,
                        errorElement: <BookingSlotDetailsError />,
                      },
                      {
                        path: "history",
                        element: <BookingHistory />,
                        errorElement: <BookingSlotDetailsError />,
                      },
                      {
                        path: "chat",
                        element: <BookingChat />,
                        errorElement: <BookingSlotDetailsError />,
                      },
                    ],
                  },
                  {
                    loader: managementSlotLoader,
                    path: "management/:slotId",
                    element: <ManagementDetailsHeader />,
                    errorElement: <ManagementSlotDetailsError />,
                    children: [
                      { index: true, loader: () => redirect("info") },
                      {
                        path: "info",
                        element: <ManagementInfo />,
                        errorElement: <ManagementSlotDetailsError />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "/hall-scheme",
                element: <HallScheme />,
              },
              {
                path: "/create-booking",
                element: <HallContainer isCreateBookingOpened />,
              },
              {
                path: "/create-booking-new",
                element: <ReservationForm />,
                children: [
                  { path: "step-1", element: <div>step-1</div> },
                  { path: "step-2", element: <div>step-2</div> },
                  { path: "step-3", element: <div>step-3</div> },
                  { path: "step-4", element: <div>step-4</div> },
                  { path: "step-5", element: <div>step-5</div> },
                ],
              },
              {
                path: "/hall",
                element: <HallContainer />,
              },
              {
                path: "/requests",
                element: <BookingOrders />,
              },
              {
                path: "/guests",
                loader: guestsLoader,
                element: <Guests />,
                children: [
                  {
                    index: true,
                    element: <EmptyGuestDetails />,
                  },
                  {
                    path: "create",
                    element: <GuestFormContainer />,
                  },
                  {
                    path: ":clientId",
                    element: <GuestCard />,
                    children: [
                      {
                        index: true,
                        loader: () => redirect("info"),
                      },
                      {
                        path: "info",
                        element: <GuestInfo />,
                        errorElement: <GuestInfoError />,
                      },
                      {
                        path: "history",
                        element: <GuestHistory />,
                        errorElement: <GuestHistoryError />,
                      },
                      {
                        path: "notes",
                        element: <GuestNotes />,
                        errorElement: <GuestNotesError />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "/grid",
                loader: () =>
                  config.requestGrid ? null : redirect("/dashboard"),
                element: <RequestsGrid />,
              },
              {
                path: "/dialogs",
                element: <Dialogs />,
                children: [
                  {
                    path: ":dialogId",
                    loader: dialogLoader,
                    element: <Dialog />,
                    children: [
                      { index: true, element: <FeedList /> },
                      {
                        path: "create-booking",
                        element: <ReservationFromDialog />,
                      },
                      {
                        path: "edit/:record",
                        loader: editRecordLoader,
                        element: <EditRecordFromDialog />,
                      },
                      { path: "*", loader: () => redirect("/dialogs") },
                    ],
                  },
                ],
              },
              {
                path: "/schedule-landing",
                element: <ScheduleLanding />,
              },
              {
                path: "/settings",
                element: <Settings />,
                children: [
                  {
                    path: "roles",
                    loader: rolesLoader,
                    element: <Roles />,
                    errorElement: <RolesErrorBoundary />,
                    action: rolesAction,
                  },
                ],
              },
              {
                path: "/search",
                element: <Search />,
              },
            ],
          },
        ],
      },
      {
        loader: loginLoader,
        path: "/login",
        element: <Auth />,
        action: loginAction,
      },
      { path: "*", loader: () => redirect("/dashboard") },
    ],
  },
]);
