import { Client } from "types/client";
import { StatusId } from "types/status";
import { z } from "zod";

import {
  BookingId,
  RestaurantId,
  SeatType,
  SlotDeposit,
  SlotPlaces,
  Source,
} from "./booking.model";
import { Tag } from "./tags.model";

const UserId = z.number().brand("UserId");
const HistoryId = z.number().brand("HistoryId");

export const BookingHistoryDTO = z.object({
  booking: z.object({
    id: BookingId,
    restaurant_id: RestaurantId,
    date: z.string().date(),
    time: z.string().refine((val) => /^\d\d:\d\d:\d\d$/.test(val)),
    visit_duration: z.number(),
    slot_places: SlotPlaces,
    notes: z.record(z.string()),
    visitors: z.number(),
    locked: z.boolean(),
    overbooking: z.boolean(),
    use_deposit: SlotDeposit.shape.use_deposit,
    deposit_status: SlotDeposit.shape.deposit_status,
    deposit_amount: SlotDeposit.shape.deposit_amount,
    tags: Tag.array(),
    status_id: StatusId,
    extra_status_id: StatusId.nullable(),
    source: Source.nullable(),
    contact: Client,
    seat_type: SeatType,
    comment: z.string(),
    user: z.object({
      serial: z.number().nullable(),
      login: z.string(),
      name: z.string(),
      active: z.boolean(),
      user_id: UserId,
      old_id: z.number().nullable(),
    }),
  }),
  events: z
    .object({
      history_id: HistoryId,
      time: z.string().date(),
      user: z.string(),
      event: z.enum([
        "CREATE",
        "MODIFY",
        "OVERBOOKING",
        "CHANGE_STATUS",
        "CHANGE_EXTRA_STATUS",
        "MOVING_PLACE",
        "RESTORE",
      ]),
    })
    .array(),
});
export type BookingHistoryDTO = z.infer<typeof BookingHistoryDTO>;
