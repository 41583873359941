import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { getBookingEndTime, getBookingStartTime } from "utils";

import {
  getTimeHoursAndMinutes,
  minsToString,
  minsToStringManagerialTable,
} from "../../common/helpers";
import { ActionTypeSelector, HallMode } from "../../features/HallSchema";
import { type HallSlotsDTO } from "../../features/api/hallschema-api";
import { HallTableStatus } from "../../models/hall-table";
import { Place } from "../../types/place";
import { ETranslations } from "../../types/translates";
import { TableColor } from "../hall-scheme/hall-helpers";

export const ICON_SIZE = 80;

export interface SlotExtraOptions {
  slot: HallSlotsDTO["slots"][number] | undefined;
  tableColor: TableColor;
  stripPercent: number;
  timeWord: string;
  timeString: string;
  canBook?: boolean;
  tableStatus: HallTableStatus | undefined;
}

const emptyExtraOptions: SlotExtraOptions = {
  tableColor: TableColor.no_color,
  stripPercent: -1,
  timeString: "",
  timeWord: "",
  canBook: true,
  slot: undefined,
  tableStatus: undefined,
};

export const isVisiting = (
  slot: HallSlotsDTO["slots"][number] | undefined,
  time: Dayjs,
) => {
  const start = slot && getBookingStartTime(slot?.slot.date, slot?.slot.time);
  const end =
    slot &&
    getBookingEndTime(
      slot?.slot.date,
      slot.slot.time,
      slot.slot.visit_duration,
    );
  return time.isSameOrAfter(start) && time.isSameOrBefore(end);
};

const sortSlotsByTime = (
  a: HallSlotsDTO["slots"][number],
  b: HallSlotsDTO["slots"][number],
) =>
  getBookingStartTime(a.slot.date, a.slot.time).valueOf() -
  getBookingStartTime(b.slot.date, b.slot.time).valueOf();

export function selectSlotByShift(
  slots: HallSlotsDTO["slots"],
  time: Dayjs,
  onlyCurrent = false,
): HallSlotsDTO["slots"][number] | undefined {
  if (!slots?.length) return undefined;
  const [inHallBookings, restSlots] = _.partition(slots, (s) => {
    return (
      s.slot.slot_type === "BOOKING" && s.slot.status?.category === "IN_SERVICE"
    );
  });
  const sortedSlots = restSlots.sort(sortSlotsByTime);
  const [, currentOrFuture] = _.partition(sortedSlots, (slot) => {
    return (
      getBookingStartTime(slot?.slot.date, slot?.slot.time).isBefore(
        time,
        "minute",
      ) &&
      getBookingEndTime(
        slot?.slot.date,
        slot?.slot.time,
        slot.slot.visit_duration,
      ).isBefore(time)
    );
  });

  const [minSlot] = sortedSlots;
  const [currentTimeSlots, futureSlots] = _.partition(currentOrFuture, (s) =>
    isVisiting(s, time),
  );

  const visiting = _.concat(
    inHallBookings.sort(sortSlotsByTime),
    currentTimeSlots.sort(sortSlotsByTime),
  ).filter(Boolean);
  const [nextSlot] = futureSlots;

  if (onlyCurrent) {
    return visiting[0];
  }

  const otherSlots = [nextSlot || minSlot]
    .filter(Boolean)
    .sort(sortSlotsByTime);

  const [first, ...nextBookings] = _.uniqBy(
    _.concat(visiting, otherSlots).filter(Boolean),
    (s) => s.slot.slot_id,
  );
  if (!first) return undefined;
  const isBookingEnd = getBookingEndTime(
    first.slot.date,
    first.slot.time,
    first.slot.visit_duration,
  )
    .subtract(1, "minute")
    .isBefore(time);
  if (!isBookingEnd) return first;

  const sliderNotMoved = time.isSameOrBefore(dayjs.tz(), "minute");

  // Если текущая бронь пересиживает и ползунок не двигали, возвращаем ee
  // Теперь если ползунок не двигали вперед =)
  if (
    sliderNotMoved &&
    first.slot.slot_type === "BOOKING" &&
    first.slot.status?.category === "IN_SERVICE"
  ) {
    return first;
  }

  return nextBookings.find(
    (b) =>
      b.slot.slot_type === "BOOKING" &&
      b.slot.status?.category !== "IN_SERVICE" &&
      time.isBefore(
        getBookingEndTime(b.slot.date, b.slot.time, b.slot.visit_duration),
      ),
  );
}

export function getHallTableStatus(
  slot: HallSlotsDTO["slots"][number],
  time: Dayjs,
): HallTableStatus | undefined {
  const now = dayjs.tz();
  const statusSystemName =
    slot.slot.slot_type === "BOOKING" ? slot.slot.status?.system_name : "";
  const start = getBookingStartTime(slot.slot.date, slot.slot.time);
  const isChangedTime = dayjs.tz().isBefore(time, "minute");
  const isBookingEnd = getBookingEndTime(
    slot.slot.date,
    slot.slot.time,
    slot.slot.visit_duration,
  ).isBefore(time);
  if (statusSystemName === "IN_HALL" && isBookingEnd && !isChangedTime)
    return HallTableStatus.delay_end;
  if (statusSystemName === "IN_HALL" && !isBookingEnd)
    return HallTableStatus.in_hall;
  if (slot.slot?.slot_type === "MANAGER") return HallTableStatus.managerial;

  if (isBookingEnd) return undefined;

  if (["CONFIRMED", "EXTERNAL", "NEW"].includes(statusSystemName)) {
    if (time.isBefore(start, "minute")) return HallTableStatus.booked;
    if (time.isSameOrAfter(start, "minutes")) {
      if (now.isSameOrAfter(start, "minute") && now.isSame(start, "date")) {
        return HallTableStatus.delay_start;
      }
      return HallTableStatus.busy;
    }
  }
  return undefined;
}

export function getOptions(
  slot: HallSlotsDTO["slots"][number],
  time: Dayjs,
  status?: HallTableStatus,
) {
  const defaultValues = {
    tableColor: TableColor.no_color,
    timeWord: "",
    timeString: "",
    stripPercent: -1,
  };

  const timeString = slot.start_time
    ? dayjs(slot.start_time, "HH:mm:ss").format("HH:mm")
    : defaultValues.timeString;
  const minutesAfterStart = time.diff(
    getBookingStartTime(slot.slot.date, slot.slot.time),
    "minutes",
  );
  const minutesBeforeEnd = getBookingEndTime(
    slot.slot.date,
    slot.slot.time,
    slot.slot.visit_duration,
  ).diff(time, "minutes");

  switch (status) {
    case HallTableStatus.booked: {
      const diff = dayjs.duration({ minutes: Math.abs(minutesAfterStart) });
      const { minutes, hours } = getTimeHoursAndMinutes(diff.asMinutes());
      return {
        ...defaultValues,
        tableColor: TableColor.light_grey,
        timeWord: ETranslations.BOOKING_SOON,
        timeString: `${hours}:${minutes < 10 ? "0" + minutes : minutes}`,
      };
    }

    case HallTableStatus.managerial:
      return {
        ...defaultValues,
        tableColor: TableColor.purple,
        timeString: minsToStringManagerialTable(minutesAfterStart),
        stripPercent: (minutesBeforeEnd * 100) / slot.slot.visit_duration,
      };
    case HallTableStatus.delay_start:
      return {
        ...defaultValues,
        tableColor: TableColor.yellow,
        timeString: minsToString(minutesAfterStart),
        stripPercent: (minutesBeforeEnd * 100) / slot.slot.visit_duration,
      };
    case HallTableStatus.in_hall:
    case HallTableStatus.busy:
      return {
        ...defaultValues,
        tableColor: TableColor.green,
        timeString: minsToString(minutesBeforeEnd),
        stripPercent: (minutesBeforeEnd * 100) / slot.slot.visit_duration,
      };
    case HallTableStatus.delay_end:
      return {
        ...defaultValues,
        tableColor: TableColor.red,
        timeString: minsToString(Math.abs(minutesBeforeEnd)),
        stripPercent: 100,
      };
    case HallTableStatus.booked_not_conf:
      return {
        ...defaultValues,
        tableColor: TableColor.no_color,
        timeString,
      };
    default:
      return defaultValues;
  }
}

export function getSlotExtraOptions(
  slot: HallSlotsDTO["slots"][number] | undefined,
  time: Dayjs,
): SlotExtraOptions {
  if (
    !slot ||
    (slot.slot.slot_type === "BOOKING" &&
      slot.slot.status.category === "TERMINAL")
  )
    return emptyExtraOptions;
  const tableStatus = getHallTableStatus(slot, time);
  return { ...getOptions(slot, time, tableStatus), slot, tableStatus };
}

export const getType = (hallMode: HallMode, bookingId?: number | null) =>
  useMemo((): ActionTypeSelector => {
    switch (hallMode) {
      case HallMode.TABLES:
        if (bookingId) return "disable-reg";
        return "full";
      case HallMode.MOVE_BOOKINGS_CAPTURED:
      case HallMode.TABLE_BOOKINGS_MOVE:
        return "swap-captured";
      // case HallMode.MOVE_BOOKINGS:
      //   return 'only-swap';
      case HallMode.BOOKING_GUEST:
      case HallMode.BOOKING_HALL:
      case HallMode.REGISTRATION_GUESTS:
      case HallMode.MANAGERIAL_BOOKING:
      case HallMode.MANAGERIAL_HALL:
      case HallMode.MANAGERIAL_HALL_BOOKING:
      case HallMode.REGISTRATION_HALL:
      case HallMode.TABLE_BOOKINGS_EDIT:
      case HallMode.EDIT_HALL:
      case HallMode.BOOKING_TABLET:
        return "select-table";
      default:
        return "full";
    }
  }, [hallMode, bookingId]);

export const getCenterCoords = (place: Place | undefined, schemeFactor = 1) => {
  if (!place) return null;
  const { schemaTable } = place;

  const centerX =
    schemaTable.x * schemeFactor + (schemaTable.width * schemeFactor) / 2;
  const centerY =
    schemaTable.y * schemeFactor + (schemaTable.height * schemeFactor) / 2;
  return { x: centerX, y: centerY };
};

export const sumOfSquares = (x: number, y: number): number => x ** 2 + y ** 2;
