import cn from "classnames";
import { type ChangeEventHandler, useState } from "react";
import { ICONS, Input } from "ui-kit";

import styles from "./InputPassword.module.scss";

const EyeCheckbox = ({
  className,
  value,
  onChange,
}: {
  className?: string;
  value?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}) => (
  <label className={cn(styles.eye, className)}>
    <input
      checked={value}
      onChange={onChange}
      data-eyeCheckbox
      hidden
      type="checkbox"
    />
    <ICONS.Eye />
  </label>
);

export const InputPassword = ({
  className,
  inputClassName,
  label,
  name,
  invalid,
  autoComplete,
  error,
}: {
  className?: string;
  inputClassName?: string;
  label?: string;
  name?: string;
  invalid?: boolean;
  autoComplete?: string;
  error?: string | string[];
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <Input
      className={className}
      inputClassName={inputClassName}
      label={label}
      name={name}
      type={isPasswordVisible ? "text" : "password"}
      suffix={
        <EyeCheckbox
          value={isPasswordVisible}
          onChange={() => setIsPasswordVisible(!isPasswordVisible)}
        />
      }
      invalid={invalid}
      autoComplete={autoComplete}
      error={error}
    />
  );
};
