import { useForm } from "react-hook-form";

import { FormStepper } from "./FormStepper";

export const ReservationForm = () => {
  const lol = useForm();
  return (
    <FormStepper
      stepsInfo={[
        { title: "Выбор даты", description: "14 июня" },
        {
          title: "Количество гостей",
          description: "Необходимо изменить параметры",
          valid: false,
        },
        {
          title: "Гость",
          description: "К.В. Константинопольский +7\u00A0(917)\u00A0210-33-31",
          valid: true,
        },
        {
          title: "Стол",
          description: "18 стол, 3 зал",
          valid: true,
        },
        {
          title: "Прочее",
          description: "Дополнительная информация",
        },
      ]}
      startFromStep={1}
      idPrefix="reservation-form-step"
    >
      <button>submit</button>
    </FormStepper>
  );
};
