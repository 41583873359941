import { restaurantSelector } from "features/AppContex";
import { useFromProxyActions } from "features/BookingFormProxy";
import {
  hasBookingSelector,
  selectBooking,
} from "features/BookingFormProxy/selectors";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import {
  tableBookingSearchSelector,
  tableBookingsTableSelector,
} from "features/TableBooking/selectors";
import { useTableBookingListActions } from "features/TableBooking/slice";
import { usePlacesTable } from "features/api/tables-api";
import type { BookingSlot } from "models/booking.model";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import { useIntlUtils } from "../../../../../hooks/useIntlUtils";
import { ETranslations } from "../../../../../types/translates";
import { useTablesBookings } from "../useTableBookings";

const useTableBookingList = () => {
  const { switchMode } = useHallSchemaActions();
  const { reset: resetTable, setSearchValue } = useTableBookingListActions();
  const { reset: resetBooking } = useFromProxyActions();
  const userSelectBooking = useSelector(hasBookingSelector);
  const searchValue = useSelector(tableBookingSearchSelector);
  const table = useSelector(tableBookingsTableSelector);
  const booking = useSelector(selectBooking);
  const navigate = useNavigate();

  const { data, isLoading } = useTablesBookings(undefined, !table, searchValue);
  const { table: tableData } = usePlacesTable(table);

  const bookingList = useMemo(
    () =>
      data?.slots?.filter(
        (slot) =>
          slot.slot_type !== "BOOKING" || slot.status?.category !== "TERMINAL",
      ) || [],
    [data],
  );

  const closeWindowHandler = useCallback(() => {
    // switch mode
    switchMode(HallMode.TABLES);
    // reset table
    resetTable();
    navigate("/hall-scheme");
  }, [resetTable, switchMode]);
  // todo: calculate mode for view
  // todo: read selected booking, calc

  const handleClose = useMemo(
    () =>
      userSelectBooking
        ? () => {
            resetBooking();
            switchMode(HallMode.TABLE_BOOKINGS_LIST);
          }
        : closeWindowHandler,
    [userSelectBooking, resetBooking, closeWindowHandler],
  );

  const rest = useSelector(restaurantSelector);
  useUpdateEffect(closeWindowHandler, [rest]);

  const isRegisterDisabled = useMemo(
    () =>
      bookingList.some(
        (b) => (b as BookingSlot).status?.category === "IN_SERVICE",
      ),
    [bookingList],
  );

  const isEdit = userSelectBooking && booking;

  const { getIntlEntityEdition, isRussianLocale } = useIntlUtils();

  const intlTitles = useMemo(
    () => ({
      bookingEdit: getIntlEntityEdition(
        isRussianLocale
          ? ETranslations.PLURAL_BOOKINGS_NOM
          : ETranslations.PLURAL_BOOKING,
      ),
    }),
    [],
  );
  return {
    closeWindowHandler,
    handleClose,
    tableData,
    searchValue,
    setSearchValue,
    isEdit,
    intlTitles,
    isLoading,
    booking,
    bookingList,
    isRegisterDisabled,
  };
};

export default useTableBookingList;
