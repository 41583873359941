import { DndPreviewContainer } from "components/DndPreviewLayouts";
import { AppDialogsProvider } from "containers/Dialogs";
import { AppUsersProvider } from "contexts/AppUsersContext";
import { LayoutContextProvider } from "contexts/LayoutContext";
import ApplicationContextWrapper from "contexts/ModalContext";
import { Suspense } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DndProvider,
  MouseTransition,
  type MultiBackendOptions,
  TouchTransition,
} from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Outlet } from "react-router-dom";
import { Spinner } from "ui-kit";


const HTML5toTouch: MultiBackendOptions = {
  backends: [
    {
      id: "html5",
      backend: HTML5Backend,
      transition: MouseTransition,
      preview: true,
    },
    {
      id: "touch",
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
        // поддержка long tap на touch-устройствах (без которой long tap отменит drag)
        ignoreContextMenu: true,
      },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

export const AppProvider = () => (
  <Suspense fallback={<Spinner />}>
    <ApplicationContextWrapper>
      <LayoutContextProvider>
        <AppUsersProvider>
          <DndProvider options={HTML5toTouch}>
            <AppDialogsProvider>
              <Outlet />
            </AppDialogsProvider>
            <DndPreviewContainer />
          </DndProvider>
        </AppUsersProvider>
      </LayoutContextProvider>
    </ApplicationContextWrapper>
  </Suspense>
);