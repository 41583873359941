import { Role } from "services/auth.service";
import { z } from "zod";

import { RestaurantId } from "./booking.model";

export class AppointUser {
  constructor(
    public name: string,
    public login: string,
    public restaurant_id: number,
    public role: Role,
    public photo: string | null,
    public phone: string | null,
    public id?: number,
    public max_guests?: number,
    public password?: string,
  ) {}
}

export const UserId = z.string().uuid().brand("UserId");
export type UserId = z.infer<typeof UserId>;

export const User = z.object({
  serial_id: UserId,
  user_id: z.number(),
  user_name: z.string(),
  user_login: z.string(),
});

export type User = z.infer<typeof User>;

const UserGroupType = z.enum(["NOT_PERSONAL", "PERSONAL"]);

export const UserGroup = z.object({
  group_type: UserGroupType,
  group_name: z.string(),
  group_translation_name: z.string(),
  group_weight: z.number(),
});
export type UserGroup = z.infer<typeof UserGroup>;

export const GrantedUsersDTO = z
  .object({
    user_serial: UserId,
    username: z.string(),
    user_name: z.string().optional(),
    user_group: UserGroup,
    active_in_restaurants: RestaurantId.array(),
  })
  .array();
export type GrantedUser = z.infer<typeof GrantedUsersDTO>[number];
