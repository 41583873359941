import cn from "classnames";
import React, {
  type ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import { useToggle } from "react-use";

import { Button, ICONS } from "..";
import styles from "./Collapse.module.scss";

interface CollapseProps {
  header: React.ReactNode;
  onClick?: () => void;
  headerClassName?: string;
  iconClassName?: string;
  initialOpen?: boolean;
  autoHeight?: boolean;
  expendInHeader?: boolean;
}

export const Collapse: React.FC<CollapseProps> = ({
  header,
  children,
  onClick,
  headerClassName,
  iconClassName,
  initialOpen,
  autoHeight,
  expendInHeader = true,
}) => {
  const [isActive, toggleIsActive] = useToggle(Boolean(initialOpen));

  const content = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    const { current: container } = content;
    if (!container) return;
    if (isActive) {
      container.style.height = "";
      const { scrollHeight } = container;
      container.style.height = autoHeight ? "auto" : `${scrollHeight}px`;
    } else {
      container.style.height = "0";
    }
  }, [children, isActive]);

  const toggle = useCallback(
    (e) => {
      e.stopPropagation();
      toggleIsActive();
    },
    [toggleIsActive],
  );

  return (
    <div className={styles.collapse}>
      <div
        className={cn(styles.header, headerClassName, {
          [styles.active]: isActive,
        })}
        onClick={expendInHeader ? onClick || toggle : () => {}}
      >
        {header}
        {children ? (
          <Button
            className={cn(styles.chevron__block, { [styles.rotate]: isActive })}
            variant="phantom"
            onClick={toggle}
          >
            <ICONS.ArrowDown className={cn(styles.icon, iconClassName)} />
          </Button>
        ) : null}
      </div>
      <div
        className={cn(styles["collapse-body"])}
        ref={content}
        style={{ height: "0" }}
      >
        {children}
      </div>
    </div>
  );
};

export const ControlledCollapse = ({
  title,
  className,
  addon,
  children,
  onChange,
  isOpen,
}: {
  title: string;
  className?: string;
  addon?: ReactNode;
  children: ReactNode;
  onChange: (value: boolean) => void;
  isOpen: boolean;
}) => {
  return (
    <>
      <label className={cn(styles.controlledCollapse, className)}>
        <input
          type="checkbox"
          hidden
          checked={isOpen}
          onChange={(e) => onChange(e.currentTarget.checked)}
        />
        <strong className={styles.title}>{title}</strong>
        <em>
          {addon}
          <ICONS.ArrowDown className={styles.arrow} />
        </em>
      </label>
      <section className={cn(styles.content, { [styles.open]: isOpen })}>
        {isOpen && children}
      </section>
    </>
  );
};

export const UncontrolledCollapse = ({
  title,
  className,
  addon,
  children,
  forceOpen,
}: {
  title: string;
  className?: string;
  addon?: ReactNode;
  children: ReactNode;
  forceOpen?: boolean;
}) => {
  return (
    <>
      <details open={forceOpen} className={styles.detailsCollapse}>
        <summary className={className}>
          <dfn role="term" className={styles.title}>
            {title}
          </dfn>
          <em>
            {addon}
            <ICONS.ArrowDown className={styles.arrow} />
          </em>
        </summary>
      </details>
      <section role="definition" className={styles.content}>
        {children}
      </section>
    </>
  );
};

export type CollapsesOption<T extends {}> = {
  title: string;
  collapseData: T[];
  addon?: ReactNode;
};

const CollapsesRoot = <T extends {}>({
  options,
  isExpandedAll,
  children,
}: {
  options: CollapsesOption<T>[];
  isExpandedAll?: boolean;
  children: (data: T) => ReactNode;
}) => {
  return (
    <ul className={styles.collapses}>
      {/* Если в опции есть какие-то данные, то создаем коллапс */}
      {options.reduce<JSX.Element[]>((result, option) => {
        option.collapseData?.length &&
          result.push(
            <li key={option.title}>
              <UncontrolledCollapse
                title={option.title}
                addon={option.addon}
                forceOpen={isExpandedAll}
              >
                <ul className={styles.list}>
                  {/* Чем конкретно наполнить каждый элемент коллапса решаем через children */}
                  {option.collapseData.map(children)}
                </ul>
              </UncontrolledCollapse>
            </li>,
          );
        return result;
      }, [])}
    </ul>
  );
};

const Item = ({ children }: { children: ReactNode }) => (
  <li className={styles.item}>{children}</li>
);

export const Collapses = {
  Root: CollapsesRoot,
  Item: Item,
};
