import { useGuestListActions } from "features/GuestsList";
import { useFetchClientQuery } from "features/api/guest-api";
import { useIntlUtils } from "hooks/useIntlUtils";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { Card, LinkButton, Spinner } from "ui-kit";

export type TDictionary = {
  id: number;
  name: string;
  nameEng: string;
};

export type TView = "info" | "edit" | "create" | null;

export function GuestCard() {
  const { clientId } = useParams<Params>();
  const { formatMessage } = useIntl();

  const { clearSelectedClient, setSelectedClient } = useGuestListActions();
  const { data: guest, isFetching } = useFetchClientQuery(clientId ?? 0, {
    skip: !clientId,
    refetchOnMountOrArgChange: 1,
  });
  //FIXME: посмотреть можно ли убрать, добавить обработку ошибок
  const loading =
    isFetching && (!guest || guest?.client_id !== Number(clientId));

  useEffect(() => {
    if (guest) {
      setSelectedClient(guest);
    }
  }, [guest]);

  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate("..");
  }, [guest, clearSelectedClient]);

  const links = [
    { title: formatMessage({ id: ETranslations.GUEST_CARD }), to: "info" },
    {
      title: formatMessage({ id: ETranslations.BASE_HISTORY }),
      to: "history",
    },
    { title: formatMessage({ id: ETranslations.NOTES }), to: "notes" },
  ];

  return (
    <Card onClose={handleClose}>
      <Card.NavLinkTabs links={links} />
      <Card.Content noPadding>
        {loading || !guest ? <Spinner /> : <Outlet context={guest} />}
      </Card.Content>
    </Card>
  );
}

export const EmptyGuestDetails = () => {
  const intlUtils = useIntlUtils();
  const { isRussianLocale, getIntlEntityViewingAction, getIntlCreatingOf } =
    intlUtils;
  return (
    <Card className="guest-card-empty create-guest">
      <div className="img contact" />
      <p>
        {getIntlEntityViewingAction(
          ETranslations.PLURAL_GUESTS_THEY,
          ETranslations.PLURAL_GUESTS_ALT,
        )}
      </p>
      <LinkButton variant="primary" type="button" to={"create"}>
        {getIntlCreatingOf(
          isRussianLocale
            ? ETranslations.PLURAL_GUESTS_ALT
            : ETranslations.PLURAL_GUEST,
        )}
      </LinkButton>
    </Card>
  );
};
