import { validateClient } from "components/registration/validators/validators";
import { config } from "config";
import { useFromProxyActions } from "features/BookingFormProxy";
import {
  isClientEditing,
  selectedGuestSelector,
} from "features/BookingFormProxy/selectors";
import { useIntlUtils } from "hooks/useIntlUtils";
import type { Client as OldClient } from "models/client.model";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useField } from "react-final-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useUnmount } from "react-use";
import { Client } from "types/client";

const useClient = ({
  value,

  onChange,
  disabled,
  isWithPhone,
  isCreateBooking,
}: any) => {
  const { intl } = useIntlUtils();
  const clientFull: Client | OldClient | null =
    useSelector(selectedGuestSelector) || value;
  const {
    meta: { invalid: error },
    input: { value: phone },
  } = useField("phone");

  const defaultFullName = {
    name: clientFull?.name || "",
    surname: clientFull?.surname || "",
    middle_name: (clientFull as OldClient | null)?.middle_name || "",
  } as const;

  const [fullNameValue, setfullNameValue] = useState(() => defaultFullName);

  const editClient = useSelector(isClientEditing);
  const { setEditClient } = useFromProxyActions();
  const goesEditClient = useCallback(() => {
    setEditClient(true);
  }, [setEditClient]);

  useUnmount(() => {
    setEditClient(false);
  });
  const hide = !!error || !phone || !isValidPhoneNumber(phone);

  // FIXME: уточнить потребность
  /*  useEffect(() => {
    if (isCreateBooking) {
      setfullNameValue({
        name: "",
        surname: "",
        middle_name: "",
      });
    }
    setEditClient(true);
  }, [isWithPhone]);
 */
  // эффект управляет ручным вводом данных
  useEffect(() => {
    const mergedClientObj = {
      ...value,
      ...fullNameValue,
      editClient,
    };
    onChange(mergedClientObj);
  }, [fullNameValue]);

  useEffect(() => {
    onChange(clientFull);
    setfullNameValue({
      name: clientFull?.name || "",
      surname: clientFull?.surname || "",
      middle_name: (clientFull as OldClient | null)?.middle_name || "",
    });
  }, [clientFull?.client_id]);

  // эффект отключает редактирование поля юзера при переключение карточек бронирования. И устанавливает новое имя в стейт.
  useEffect(() => {
    onChange(clientFull);
    setEditClient(!!validateClient(clientFull, isWithPhone)); // включить режим редактирования, если поля не валидны
  }, [clientFull?.client_id]);

  const isDisabled = disabled;
  const fullRow = !config.clientsHasEmail;

  const handleClientName = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const inputValue = target.value;
    const inputName = target.name as keyof typeof defaultFullName;
    setfullNameValue((prev) => ({
      ...prev,
      [inputName]: inputValue,
    }));
  };

  const handleEmailChange = ({
    target: { value: nextValue },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, email: nextValue });
  };

  const email = value.email;

  return {
    hide,
    fullRow,
    fullNameValue,
    handleClientName,
    editClient,
    isDisabled,
    clientFull,
    goesEditClient,
    intl,
    email,
    handleEmailChange,
  };
};

export default useClient;
