import { useRecoverBookingStatusMutation } from "features/api/bookings-api";
import type { SlotId } from "models/booking.model";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";

import { ETranslations } from "../types/translates";

export function useRecoveryBooking(slotId: SlotId | undefined) {
  const [recoverStatus, { isLoading }] = useRecoverBookingStatusMutation();
  const intl = useIntl();

  const recoveryWithId = useCallback(async (bookingId: number) => {
    try {
      await recoverStatus({ bookingId }).unwrap();
      Notification.success(
        intl.formatMessage({ id: ETranslations.PREV_BOOKING_STATUS_RESTORED }),
      );
    } catch (e) {
      Notification.error({
        title: intl.formatMessage({
          id: ETranslations.UNABLE_TO_RESTORE_BOOKING_STATUS,
        }),
        message: (e as ErrorResponse["error"])?.data?.errorMessage,
      });
    }
  }, []);

  const recovery = useCallback(async () => {
    if (!slotId) return;
    await recoveryWithId(slotId);
  }, [slotId]);
  return {
    recovery,
    recoveryWithId,
    isRecovering: isLoading,
  };
}
