import {
  type LoaderFunction,
  Outlet,
  useLocation,
} from "react-router-dom";
import { GuestList } from "./GuestList";

export const loader = (({ request, params }) => {
  const url = new URL(request.url);
  const isRootGuests =
    url.pathname === "/guests" || url.pathname === "/guests/";
  return { isRootGuests };
}) satisfies LoaderFunction;

export function Guests() {
  const { pathname } = useLocation();
  const isRootGuests = pathname === "/guests" || pathname === "/guests/";

  return (
    <>
      <GuestList hideOnTablet={!isRootGuests} />
      <Outlet />
    </>
  );
}
