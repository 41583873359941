import { useCreateWithOverbooking } from "components/hall-scheme/redux/Booking/hooks/useCreateWithOverbooking";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import {
  useFetchClientQuery,
  useLazyFetchClientQuery,
  useUpdateGuestMutation,
} from "features/api/guest-api";
import { useIsTabletBooking } from "hooks/useIsTablet";
import type { Client } from "models/client.model";
import type { IResponse } from "models/common";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import { invariant } from "utils";

import { useFeedsManagementContext } from "../Dialog/FeedsManagementContext";
import type { Booking } from "types/booking";

export const useReservationFromDialog = () => {
  const { clientId, dialogId, optimisticReservationLink, changeRestaurant } =
    useFeedsManagementContext();
  const { formatMessage } = useIntl();
  const { data: clientData, isLoading, error } = useFetchClientQuery(clientId);
  const {
    create,
    createWithOverbooking,
    isConfirmationRequired,
    clearData,
    isCreating,
    hasManagerialError,
    closeModal,
  } = useCreateWithOverbooking();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { switchMode } = useHallSchemaActions();
  const isTablet = useIsTabletBooking();
  const [update] = useUpdateGuestMutation();
  const [getClient] = useLazyFetchClientQuery();

  const afterSubmit = (data: IResponse<Booking> | undefined) => {
    data?.data?.feed_id && optimisticReservationLink(data.data.feed_id);
    navigate({
      pathname: `/dialogs/${dialogId}`,
      search,
    });
  };

  const createBooking = useCallback(
    async (data) => {
      const client = data.client || data.contact || {};
      const { name = "", surname = "", middle_name = "" } = client;
      const client_id = client.client_id || clientData?.client_id || clientId;
      const currentClient =
        (client.client_id && client.client_id !== clientId) || !clientData
          ? (await getClient(client_id)).data
          : clientData;
      !currentClient &&
        console.log(">>>>>", {
          dialogId,
          clientId,
          client_id,
          client,
          data,
          clientData,
        });
      invariant(
        currentClient,
        formatMessage({ id: ETranslations.ERROR_CLIENT_MUST_BE_VALID }),
        true,
      );
      const isEdited =
        currentClient.phone !== data.phone ||
        currentClient.name !== name ||
        currentClient.surname !== surname ||
        currentClient.middle_name !== middle_name;

      const updatedClient: Client = {
        ...currentClient,
        phone: data.phone,
        name,
        surname,
        middle_name,
        email: data.email || currentClient.email,
      };

      isEdited && update(updatedClient);

      return create({
        ...data,
        client: { ...data.client, ...updatedClient },
        contact: null,
        seatType: "BOOK",
      }).then(afterSubmit);
    },
    [create],
  );

  const createWithOver = useCallback(() => {
    return createWithOverbooking().then(afterSubmit);
  }, [createWithOverbooking]);

  const handleChangeMode = () => {
    switchMode(
      isTablet ? HallMode.MANAGERIAL_TABLET : HallMode.MANAGERIAL_BOOKING,
    );
  };

  return {
    formatMessage,
    handleChangeMode,
    createBooking,
    isConfirmationRequired,
    clearData,
    createWithOver,
    isCreating,
    hasManagerialError,
    closeModal,
    afterSubmit,
    isLoading,
    clientData,
    error,
    changeRestaurant,
  };
};
