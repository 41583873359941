import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, Card } from "ui-kit";

import { TAB_KEY, type Tab } from "../constans";
import styles from "./Dialog.module.scss";

export const DialogTabs = ({
  activeTab,
  children,
  closeDialog,
  outlet,
}: {
  activeTab: Tab;
  children: ReactNode;
  closeDialog: ReactNode;
  outlet: JSX.Element;
}) => {
  const { pathname } = useLocation();
  const isCreateReservation = pathname.includes("create-booking");
  const isEditFeed = pathname.includes("/edit");
  const [, setSearchParams] = useSearchParams();
  const setTab = (tab: Tab) =>
    setSearchParams((prev) => (prev.set(TAB_KEY, tab), prev));
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  return (
    <Card>
      <Card.HeaderTabs<Tab>
        activeTab={activeTab}
        onChange={setTab}
        className={styles.headerTabs}
      >
        <div />
        <Card.HeaderTabs.Item<Tab>
          tabName={"CHAT"}
          title={formatMessage({ id: ETranslations.CHAT })}
        >
          {children}
        </Card.HeaderTabs.Item>
        {isEditFeed ? (
          <>
            <Card.HeaderTabs.Item<Tab>
              tabName={"EDIT"}
              title={formatMessage({
                id: ETranslations.BOOKING_MANAGEMENT,
              })}
            >
              {outlet}
            </Card.HeaderTabs.Item>
            <Card.HeaderTabs.Item<Tab>
              tabName={"HISTORY"}
              title={formatMessage(
                { id: ETranslations.HISTORY_OF },
                {
                  value: formatMessage({
                    id: ETranslations.PLURAL_CHANGES,
                  }).toLowerCase(),
                },
              )}
            >
              {outlet}
            </Card.HeaderTabs.Item>
          </>
        ) : (
          <Card.HeaderTabs.Item<Tab>
            tabName={"FEEDS"}
            title={formatMessage({
              id: ETranslations[
                isCreateReservation ? "CREATION_RESERVATION" : "BASE_BOOKINGS"
              ],
            })}
          >
            {outlet}
          </Card.HeaderTabs.Item>
        )}
        <Card.HeaderTabs.Addon className={styles.addonButtons}>
          {activeTab === "CHAT" && closeDialog}
          <Button variant="primary" onClick={() => navigate("/dialogs")}>
            {formatMessage({ id: ETranslations.BACK_TO_DIALOGS })}
          </Button>
        </Card.HeaderTabs.Addon>
      </Card.HeaderTabs>
    </Card>
  );
};
