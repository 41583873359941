import cn from "classnames";
import { useLinkDialogTask, useUnlinkDialogTask } from "features/Dialogs/SDK";
import type { MouseEventHandler, ReactNode } from "react";
import { memo, useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Notification } from "services/notification";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { ICONS } from "ui-kit";

import { useFeedsManagementContext } from "../Dialog/FeedsManagementContext";
import { RESERVATION_DETAILS_TABS, TAB_KEY } from "../constans";
import styles from "./FeedList.module.scss";
import type { StatusIcon } from "./utils";

const LinkButton = ({
  dialogId,
  feedId,
  isLinked,
  children,
}: {
  dialogId: string;
  feedId: string;
  isLinked?: boolean;
  children: ReactNode;
}) => {
  const { addFeedToLinked, removeFeedFromLinked } = useFeedsManagementContext();
  const { formatMessage } = useIntl();
  const {
    link,
    loading: linking,
    done: doneLinking,
    error: errorLinking,
  } = useLinkDialogTask(dialogId, feedId);
  const {
    unlink,
    loading: unlinking,
    done: doneUnlinking,
    error: errorUnlinking,
  } = useUnlinkDialogTask(dialogId, feedId);

  useEffect(() => {
    errorUnlinking &&
      (addFeedToLinked(feedId),
      Notification.error(formatMessage({ id: ETranslations.ERROR_UNLINKING })));
    errorLinking &&
      (removeFeedFromLinked(feedId),
      Notification.error(formatMessage({ id: ETranslations.ERROR_LINKING })));
  }, [errorUnlinking, errorLinking]);

  useEffect(() => {
    doneUnlinking && removeFeedFromLinked(feedId);
    doneLinking && addFeedToLinked(feedId);
  }, [doneUnlinking, doneLinking]);

  const isDisabled = !dialogId || linking || unlinking;

  const handleLinkClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    isLinked ? unlink() : link();
  };

  return (
    <button
      className={cn({
        [styles.unlinked]: !isLinked || unlinking,
        [styles.linked]: isLinked || linking,
        [styles.loadLinking]: linking || unlinking,
      })}
      disabled={isDisabled}
      onClick={handleLinkClick}
    >
      {children}
    </button>
  );
};

export const FeedCard = memo(
  ({
    restaurantName,
    statusText,
    StatusIcon,
    date,
    time,
    persons,
    feedId,
    record,
    isLinked,
    dialogId,
  }: {
    restaurantName: string;
    statusText: string;
    StatusIcon?: StatusIcon;
    date: string;
    time: string;
    persons: number | "···";
    feedId: string;
    record?: Params["record"];
    isLinked?: boolean;
    dialogId?: string;
  }) => {
    const navigate = useNavigate();
    return (
      <article
        className={styles.feedCard}
        onClick={() =>
          navigate({
            pathname: `edit/${record}`,
            search: `${TAB_KEY}=${RESERVATION_DETAILS_TABS.EDIT}`,
          })
        }
        role="link"
      >
        <div className={styles.topContainer}>
          <p className={styles.restaurant}>
            <ICONS.Restaurant />
            <span className={styles.restaurantName}>{restaurantName}</span>
          </p>
          <p className={styles.statusName}>
            {StatusIcon && <StatusIcon />}
            <span>{statusText}</span>
          </p>
        </div>
        <div className={styles.bottomContainer}>
          <p>
            <ICONS.OutlineEvent />
            <span>{date}</span>
          </p>
          <p>
            <ICONS.Clocks />
            <span>{time}</span>
          </p>
          <p>
            <ICONS.People />
            <span>{persons}</span>
          </p>
          {dialogId && (
            <LinkButton dialogId={dialogId} isLinked={isLinked} feedId={feedId}>
              <ICONS.Link />
            </LinkButton>
          )}
        </div>
      </article>
    );
  },
);

export const EmptyFeedCard = () => (
  <FeedCard
    restaurantName="···"
    statusText="···"
    date="···"
    time="···"
    persons="···"
    feedId=""
  />
);
