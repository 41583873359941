import { Modal } from "components/modal";
import { useRestsOptions } from "features/AppContex/selectors";
import { type ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { Form, useActionData } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, FieldError, Input, Labeled, SelectBasic } from "ui-kit";

import type { action } from "../api/action";
import { ROLE_INTENT } from "../constants";
import styles from "./Roles.module.scss";

export const AddUserButton = ({ children }: { children: ReactNode }) => {
  const [isCreate, setIsCreate] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <Button
        variant="primary"
        onClick={(e) => {
          e.stopPropagation();
          setIsCreate(true);
        }}
      >
        {formatMessage(
          { id: ETranslations.BASE_ADD_ENTITY },
          {
            entity: formatMessage({ id: ETranslations.OF_USER }).toLowerCase(),
          },
        )}
      </Button>
      <Modal
        className={styles.userEditModal}
        isOpen={isCreate}
        title={formatMessage({ id: ETranslations.USER_CARD_UPDATING })}
        onClose={() => setIsCreate(false)}
      >
        <Modal.Content className={styles.modalBody}>{children}</Modal.Content>
        <Modal.Footer className={styles.modalFooter}>
          <Button
            name="intent"
            value={ROLE_INTENT.CREATE}
            form="create-user"
            type="submit"
            variant="primary"
          >
            {formatMessage({ id: ETranslations.BASE_SAVE })}
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => setIsCreate(false)}
          >
            {formatMessage({ id: ETranslations.BASE_CANCEL })}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const AddUserForm = ({ fullName }: { fullName?: string }) => {
  const actionData = useActionData<typeof action>();
  const { formatMessage } = useIntl();
  const restsOptions = useRestsOptions();
  return (
    <Form className={styles.userForm} id="create-user" method="POST">
      <Labeled required label={formatMessage({ id: ETranslations.ROLE })}>
        <SelectBasic
          //defaultValue={role}
          name="role"
          options={restsOptions}
          required
        />
        {actionData?.fieldErrors.role && (
          <FieldError error={actionData.fieldErrors.role} />
        )}
      </Labeled>
      <hr className={styles.divider} />
      <div className={styles.twoFields}>
        <Input
          autoComplete="off"
          name="fullName"
          defaultValue={fullName}
          label={formatMessage({ id: ETranslations.FULL_NAME })}
          required
          error={actionData?.fieldErrors.fullName}
        />
        <Input
          autoComplete="off"
          name="phone"
          label={formatMessage({ id: ETranslations.ADDITIONAL_PHONE })}
          error={actionData?.fieldErrors.phone}
        />
      </div>
      <Labeled
        required
        label={formatMessage({ id: ETranslations.PLURAL_RESTAURANT })}
      >
        <SelectBasic
          isMulti
          styleObject={{
            multiValue: {
              borderRadius: "20px",
              font: "var(--font-12-b)",
              color: "var(--gl_text_constant_primary2)",
              backgroundColor: "var(--tags_constant_background_5)",
            },
            multiValueLabel: {
              color: "var(--gl_text_constant_primary2)",
            },
          }}
          name="restaurant"
          options={restsOptions}
          required
        />
        {actionData?.fieldErrors.restaurant && (
          <FieldError error={actionData.fieldErrors.restaurant} />
        )}
      </Labeled>
      <hr className={styles.divider} />
      <Input
        required
        autoComplete="off"
        name="login"
        label={formatMessage({ id: ETranslations.AUTH_LOGIN })}
        error={actionData?.fieldErrors.login}
      />
      {actionData?.formErrors && <FieldError error={actionData.formErrors} />}
    </Form>
  );
};
