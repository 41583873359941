import { config } from "config";
import dayjs from "dayjs";
import { dateSelector } from "features/AppContex";
import { useFromProxyActions } from "features/BookingFormProxy";
import * as select from "features/BookingFormProxy/selectors";
import type { FormBooking } from "features/BookingFormProxy/types";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useTimelineActions } from "features/Timeline";
import { useCancelManagerialTableMutation } from "features/api/managerialTables";
import { useIntlUtils } from "hooks/useIntlUtils";
import { useMemo, useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { useUnmount } from "react-use";
import { ETranslations } from "types/translates";
import { capitalizeString } from "utils";


const useManagerialTablesForm = (editBooking: boolean) => {
  const { submit, reset } = useForm();
  const { valid, dirty } = useFormState();
  const [cancel] = useCancelManagerialTableMutation();
  const { resetBooking, reset: closeEdit } = useFromProxyActions();
  const {
    intl,
    getIntlJoinedParts,
    getIntlNumberOf,
    isRussianLocale,
    getIntlAddOf,
  } = useIntlUtils();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const date = useSelector(dateSelector)
  const isEditMode = useSelector(select.isEdit);
  const { switchMode } = useHallSchemaActions();

  const disabeld = useMemo(() => {
    if (editBooking && !isEditMode) {
      return true;
    }

    return false;
  }, [isEditMode, editBooking]);

  const handleSubmit = () => {
    submit()?.then(() => {
      setModalIsOpen(false);
    });
  };

  const { setTimestamp, setVisistTime } = useTimelineActions();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const { setActiveTables } = useHallSchemaActions();

  const intlTitles = useMemo(() => {
    const create = isRussianLocale
      ? capitalizeString(
          intl.formatMessage(
            { id: ETranslations.BASE_ENTITY_CREATION },
            {
              entity: intl
                .formatMessage({ id: ETranslations.BASE_BOOK })
                .toLowerCase(),
            },
          ),
        )
      : getIntlAddOf(ETranslations.PLURAL_BOOKING);
    return {
      tableNumber: getIntlNumberOf(
        isRussianLocale
          ? ETranslations.PLURAL_TABLES_ALT
          : ETranslations.PLURAL_TABLE,
      ),
      addReservation: create,
      guestWithoutPhone: getIntlJoinedParts([
        ETranslations.PLURAL_GUEST,
        ETranslations.WITHOUT,
        ETranslations.OF_PHONE,
      ]),
    };
  }, []);

  const {
    input: { value: bookingId },
  } = useField("bookingId", { subscription: { value: true } });

  const {
    input: { value: bookingDate },
  } = useField("start_date", { subscription: { value: true } });

  const {
    input: { value: bookingTime },
  } = useField("time", { subscription: { value: true } });

  const {
    input: { value: tables },
  } = useField("tables", { subscription: { value: true } });

  const {
    input: { value: duration },
  } = useField("duration", { subscription: { value: true } });

  const cancelBooking = () => {
    cancel({ id: bookingId });
    closeModal();
  };

  const handleReset = () => {
    reset();
  };

  useUnmount(() => {
    resetBooking();
  });

  const managerialBookingTimeStamp = useMemo(() => {
    return dayjs(`${bookingDate} ${bookingTime.label}`).valueOf();
  }, [bookingDate, bookingTime.label]);

  const handleOpenBooking = () => {
    switchMode(HallMode.MANAGERIAL_CREATE_BOOKING);
    setActiveTables({ activeTables: tables });
    setTimestamp(managerialBookingTimeStamp);
    closeEdit();
    //@ts-ignore
    setVisistTime(duration);
  };

  const formBookingState: Partial<FormBooking & { initialVisitTime: number}> = {
    tables,
    seatType: "MANAGER_BOOK",
    date: date.toDate(),
    visit_time: duration,
    initialVisitTime: duration,
  };
  const isNewDesign = config.newBookingDesign;

  const timePassed = useMemo(() => {
    if (
      dayjs().valueOf() >=
      dayjs(`${bookingDate}: ${bookingTime.label}`)
        .add(duration, "minute")
        .valueOf()
    ) {
      return true;
    } else {
      return false;
    }
  }, [bookingDate, bookingTime, bookingId, duration]);
  return {
    handleSubmit,
    isNewDesign,
    intl,
    disabeld,
    intlTitles,
    valid,
    openModal,
    dirty,
    handleReset,
    isEditMode,
    timePassed,
    handleOpenBooking,
    modalIsOpen,
    closeModal,
    cancelBooking,
    managerialBookingTimeStamp,
    formBookingState,
  };
};

export default useManagerialTablesForm;