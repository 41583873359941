import dayjs, { type Dayjs } from "dayjs";
import { dateSelector } from "features/AppContex";
import { useApplicationContextActions } from "features/AppContex/slice";
import { timelineSelectors, useTimelineActions } from "features/Timeline";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, DatePicker, ICONS, RadioButton } from "ui-kit";



import { ETranslations } from "../../../types/translates";
import styles from "./Header.module.scss";


export function DateControls() {
  const intl = useIntl();
  const { setDate } = useApplicationContextActions();
  const timestamp = useSelector(timelineSelectors.getTimestamp);
  const { setTimestamp, setIsActualTime } = useTimelineActions();
  const timestampDate = dayjs.tz(timestamp);

  const startDate = useSelector(dateSelector);

  const isNeedActualTime = (first: Dayjs, second: Dayjs) => {
    const firstTime = dayjs(first.format("HH:mm"), "HH:mm");
    const secondTime = dayjs(second.format("HH:mm"), "HH:mm");
    const diff = firstTime.diff(secondTime, "minutes");

    return setIsActualTime(Math.abs(diff) <= 5);
  };

  const getDay = useCallback(() => {
    if (dayjs().isSame(startDate, "day")) return "today";
    return dayjs().add(1, "day").isSame(startDate, "day")
      ? "tomorrow"
      : undefined;
  }, [startDate]);

  const prevDay = useCallback(() => {
    const now = dayjs.tz();
    const prev = startDate.clone().add(-1, "day");
    if (now.isSame(prev, "day")) {
      isNeedActualTime(now, prev);
    } else {
      setIsActualTime(false);
    }
    setTimestamp(timestampDate.add(-1, "day").valueOf());
    setDate(prev.toISOString());
  }, [startDate, setDate, timestamp]);

  const nextDay = useCallback(() => {
    const now = dayjs.tz();
    const next = startDate.clone().add(1, "day");
    if (now.isSame(next, "day")) {
      isNeedActualTime(now, next);
    } else {
      setIsActualTime(false);
    }
    setTimestamp(timestampDate.add(1, "day").valueOf());
    setDate(next.toISOString());
  }, [startDate, setDate, timestamp]);

  const switchDate = (day: "today" | "tomorrow" | undefined) => {
    const date = dayjs.tz();
    if (day === "today") {
      setTimestamp(
        date
          .clone()
          .set("hour", timestampDate.hour())
          .set("minute", timestampDate.minute())
          .valueOf(),
      );
      setDate(date.toISOString());
      isNeedActualTime(date, timestampDate);
    } else {
      const next = date.clone().add(1, "day");

      setTimestamp(
        next
          .set("hour", timestampDate.hour())
          .set("minute", timestampDate.minute())
          .valueOf(),
      );
      setDate(next.toISOString());
      setIsActualTime(false);
    }
  };

  const datePickerHandler = (date: Date) => {
    const now = dayjs.tz();
    const selectedDate = dayjs(date);
    const timestampDay = timestampDate.clone().startOf("day");
    const diffDays = selectedDate.diff(timestampDay, "day");

    const newTimestamp = timestampDate.clone().add(diffDays, "day");

    setDate(selectedDate.toISOString());
    setTimestamp(newTimestamp.valueOf());

    if (selectedDate.isSame(now, "day")) {
      isNeedActualTime(now, newTimestamp);
    } else {
      setIsActualTime(false);
    }
  };

  return (
    <>
      <RadioButton
        className={styles.dateControl}
        value={getDay()}
        onChange={switchDate}
      >
        <RadioButton.Button value="today">
          {intl.formatMessage({ id: ETranslations.TODAY })}
        </RadioButton.Button>
        <RadioButton.Button value="tomorrow">
          {intl.formatMessage({ id: ETranslations.TOMORROW })}
        </RadioButton.Button>
      </RadioButton>
      <div className={styles.calendar}>
        <Button
          className={styles.dateButton}
          variant="phantom"
          onClick={prevDay}
        >
          <ICONS.LeftArrow />
        </Button>
        <DatePicker
          className={styles.datepicker}
          customInputClassName={styles.datePickerInput}
          customInputFormatDate="ddd, DD.MM.YYYY"
          dateFormatCalendar="MMMM"
          selected={startDate.toDate()}
          onChange={datePickerHandler}
        />
        <Button
          className={styles.dateButton}
          variant="phantom"
          onClick={nextDay}
        >
          <ICONS.RightArrow />
        </Button>
      </div>
    </>
  );
}