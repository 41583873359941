import BookingHistoryLog from "components/BookingHistory/BookingHistoryLog";
import { useFetchBookingHistoryQuery } from "features/api/bookings-api";
import { useOutletContext } from "react-router-dom";
import { Spinner } from "ui-kit";

import { BookingDetailsContext } from "../DetailsHeader/BookingDetailsHeader";
import { BookingSlotDetailsError } from "../DetailsPlaceholders";

export const BookingHistory = () => {
  const booking = useOutletContext<BookingDetailsContext>();
  const { data, isError, error } = useFetchBookingHistoryQuery(booking.slot_id);
  return data ? (
    <BookingHistoryLog data={data} />
  ) : isError ? (
    <BookingSlotDetailsError message={error} />
  ) : (
    <Spinner />
  );
};
