import { DetailsManagementCard } from "components/BookingCard/ManagementCard";
import dayjs from "dayjs";
import {
  applicationContextActions,
  dateSelector,
  useApplicationContextActions,
} from "features/AppContex";
import { useFromProxyActions } from "features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useTimelineActions } from "features/Timeline";
import {
  managerialTablesApi,
  useGetManagerialTableQuery,
} from "features/api/managerialTables";
import { restaurantsApi } from "features/api/restaurants";
import { type ManagerSlot, SlotId } from "models/booking.model";
import { useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { type LoaderFunction, Outlet, useLoaderData } from "react-router-dom";
import { loadStore } from "storage";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { Card, Spinner } from "ui-kit";
import type { NavLinkTabsProps } from "ui-kit/Card/NavLinkTabs";
import { getBookingStartTime, invariant } from "utils";
import { z } from "zod";

import styles from "./DetailsHeader.module.scss";

export const loader = (async ({ params }) => {
  const { slotId } = params as Params;
  const validatedSlotId = z.coerce.number().int().pipe(SlotId).parse(slotId);

  const store = await loadStore();

  const getManagementPromise = store.dispatch(
    managerialTablesApi.endpoints.getManagerialTable.initiate(validatedSlotId),
  );

  const getRestaurantsPromise = store.dispatch(
    restaurantsApi.endpoints.fetchAllRestaurants.initiate(),
  );

  await Promise.all([getManagementPromise, getRestaurantsPromise]).then(
    (data) => {
      // Выбрасываем исключение, если не удалось загрузить бронь
      invariant(data[0].data, JSON.stringify(data[0]?.error));

      // Получаем id ресторана, к которому относится эта бронь
      const currentRestaurantId = data[0].data.restaurant_id;

      const restaurants = data[1].data;

      // Пытаемся найти этот ресторан среди ресторанов
      const currentRest = restaurants?.find(
        (r) => r.restaurant_id === currentRestaurantId,
      );
      // Если мы не смогли его найти, то скорее всего у нас нет доступа к этому ресторану, так что выбрасываем исключение
      invariant(currentRest, ETranslations.UNKNOWN_RESTAURANT);

      // Смотрим какой ресторан установлен в приложении сейчас
      const appRestaurant = store.getState().applicationContext.restaurant;

      //Если никакой ресторан не установлен, значит селектор ресторанов еще не заполнен, поэтому можем просто добавить id в localstorage
      !appRestaurant
        ? localStorage.setItem("restaurantId", String(currentRestaurantId))
        : // Иначе если id ресторана из брони не совпадает с id ресторана приложения, то устанавливаем в приложение этот ресторан
          appRestaurant.restaurant_id !== currentRestaurantId &&
          store.dispatch(applicationContextActions.setRestaurant(currentRest));
    },
  );

  return validatedSlotId;
}) satisfies LoaderFunction;

export type ManagementDetailsContext = ManagerSlot;

export const ManagementDetailsHeader = () => {
  const slotId = useLoaderData<typeof loader>();
  const { data } = useGetManagerialTableQuery(slotId);
  // сохраняем дату, которая была в приложении до открытия брони
  const previousDate = useRef(useSelector(dateSelector));
  const { formatMessage } = useIntl();
  const links = useMemo(
    () =>
      [
        {
          title: formatMessage({ id: ETranslations.BOOKING_MANAGEMENT }),
          to: "info",
        },
      ] as const satisfies NavLinkTabsProps["links"],
    [slotId],
  );
  const { setOnlyBooking } = useFromProxyActions();
  const { setPlaceFromBooking, setDate } = useApplicationContextActions();
  const { switchMode } = useHallSchemaActions();

  const { setTime } = useTimelineActions();
  useEffect(() => {
    data &&
      (setTime(getBookingStartTime(data.date, data.time)),
      setPlaceFromBooking(data),
      setOnlyBooking(data),
      switchMode(HallMode.TABLES),
      setDate(dayjs(data.date).utc(true).toISOString()));
    // Возвращаем дату, которая была в приложении до открытия брони
    return () => void setDate(previousDate.current.toISOString());
  }, [data?.slot_id]);

  return (
    <Card>
      <Card.NavLinkTabs navigateOnClose=".." links={links} />
      {!data ? (
        <Spinner />
      ) : (
        <>
          <DetailsManagementCard
            management={data}
            className={styles.detailsCard}
          />
          <Card.Content noPadding>
            <Outlet context={data} />
          </Card.Content>
        </>
      )}
    </Card>
  );
};
