import dayjs from "dayjs";
import type { ReactNode } from "react";
import type { IntlShape } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import type { FeedContent } from "types/communication";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Card, Spinner } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";
import { isNonEmptyArray } from "utils";

import { DialogsError } from "../DialogsError";
import type { Tab } from "../constans";
import { FeedCard } from "./FeedCard";
import styles from "./FeedList.module.scss";
import { FeedListContent } from "./FeedListContent";
import { useFeedList } from "./useFeedList";
import { getStatusIcon, getStatusText } from "./utils";

const ReservationFromDialogButton = ({
  disabled,
  buttonText,
}: {
  disabled: boolean;
  buttonText: string;
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <Button
      variant="primary"
      onClick={() => navigate({ pathname: "create-booking", search })}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
};

const CardContent = ({
  isLoading,
  isErrorOrEmpty,
  feeds,
  formatMessage,
  errorMessage,
  dialogId,
}: {
  isLoading: boolean;
  isErrorOrEmpty: boolean;
  feeds: FeedContent[] | undefined;
  formatMessage: IntlShape["formatMessage"];
  errorMessage: string;
  dialogId: string | undefined;
}) => (
  <Card.Content noPadding>
    {isLoading ? (
      <Spinner />
    ) : isErrorOrEmpty ? (
      <DialogsError
        message={formatMessage(
          feeds && !isNonEmptyArray(feeds)
            ? { id: ETranslations.EMPTY_BOOKINGS }
            : { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({ id: "PLURAL_BOOKINGS_NOM" }).toLowerCase(),
            error: errorMessage,
          },
        )}
      />
    ) : (
      <FeedListContent feeds={feeds!} formatMessage={formatMessage}>
        {(feed, isBooking, isLinked) => (
          <FeedCard
            date={dayjs(feed.date).format("DD.MM.YY")}
            key={feed.id.feed_id}
            persons={feed.persons}
            restaurantName={feed.partner.partner_name}
            statusText={
              isBooking
                ? formatMessage({
                    id: getStatusText(feed.status_close_state),
                  })
                : formatMessage({ id: ETranslations.ORDER })
            }
            StatusIcon={
              isBooking ? getStatusIcon(feed.status_close_state) : undefined
            }
            time={feed.time.slice(0, 5)}
            feedId={feed.id.feed_id}
            record={`${feed.id.object_type}-${feed.id.object_id}`}
            isLinked={isLinked}
            dialogId={dialogId}
          />
        )}
      </FeedListContent>
    )}
  </Card.Content>
);

const FeedsListWrapper = ({
  headerText,
  reservationFromDialog,
  children,
}: {
  headerText: string;
  reservationFromDialog: JSX.Element;
  children: ReactNode;
}) => {
  const isTablet = useIsTablet();
  return isTablet ? (
    <>
      {children}
      <BottomBar className={styles.bottomBarTablet}>
        {reservationFromDialog}
      </BottomBar>
    </>
  ) : (
    <Card className={styles.FeedList}>
      <Card.Header controls={reservationFromDialog} title={headerText} />
      {children}
    </Card>
  );
};

export const FeedList = () => {
  const {
    dialogId,
    formatMessage,
    feeds,
    isDialogLoaded,
    isLoading,
    isErrorOrEmpty,
    errorMessage,
  } = useFeedList();

  return (
    <FeedsListWrapper
      headerText={formatMessage({
        id: ETranslations.BASE_BOOKINGS,
      })}
      reservationFromDialog={
        <ReservationFromDialogButton
          buttonText={formatMessage({
            id: ETranslations.RESERVATION_FROM_DIALOG,
          })}
          disabled={!isDialogLoaded}
        />
      }
    >
      <CardContent
        isLoading={isLoading}
        isErrorOrEmpty={isErrorOrEmpty}
        feeds={feeds}
        formatMessage={formatMessage}
        errorMessage={errorMessage}
        dialogId={dialogId}
      />
    </FeedsListWrapper>
  );
};
