import { DeepPartial, createSelector } from "@reduxjs/toolkit";
import { config } from "config";
import { isEmpty } from "lodash";
import type {
  BookingSlot,
  ManagerSlot,
  OrderSlot,
  Slot,
  TempBooking,
} from "models/booking.model";
import {
  type Value as E164Number,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import type { BookingOrder } from "types/booking";
import { Client, type ClientId } from "types/client";
import { isManagerialTable } from "utils";

import { State, formProxySliceName, initialState } from "./slice";
import { FormBooking } from "./types";
import { castBookingToForm, castOrderToForm } from "./utils";

const DEFAULT_BOOKING = {};
const DEFAULT_ORDER = {};

const selectDomain = (state: any) => state[formProxySliceName] ?? initialState;
export const selectedTimeValueSelector = createSelector(
  selectDomain,
  ({ selectedTimeValue }: State) => selectedTimeValue,
);
export const hasBookingSelector = createSelector(
  selectDomain,
  ({ booking }: State) => !!booking,
);
export const selectedGuestSelector = createSelector(
  selectDomain,
  ({ client }: State) => client,
);
export const bookingTrustSelector = createSelector(
  selectDomain,
  ({ booking }: State) => {
    if (booking) return booking;
    throw new Error("Cant get booking");
  },
);

export const orderTrustSelector = createSelector(
  selectDomain,
  ({ order }: State) => {
    if (order) return order;
    throw new Error("Cant get booking order");
  },
);

export const selectOrder = createSelector(
  selectDomain,
  ({ order }: State): BookingOrder | DeepPartial<BookingOrder> => {
    if (order) return order;
    return DEFAULT_ORDER;
  },
);

/**
 * @deprecated используй booking ниже
 */
export const selectBooking = createSelector(
  selectDomain,
  ({
    booking,
  }: State): BookingSlot | DeepPartial<BookingSlot | ManagerSlot> => {
    if (booking) return booking;
    // TODO тут если нет бронирования надо возвращать null
    return DEFAULT_BOOKING;
  },
);

export const booking = createSelector(
  selectDomain,
  ({ booking: maybeBooking }: State): BookingSlot | null => {
    if (isEmpty(maybeBooking)) return null;
    if (
      !(
        maybeBooking &&
        ("slot_id" in maybeBooking || "bookingId" in maybeBooking)
      ) &&
      !isManagerialTable(maybeBooking as Slot)
    )
      return null;
    return maybeBooking as BookingSlot;
  },
);

// Если тут заменить selectBooking на booking то отваливается пред-заполнение стола,
// так как выбранных на схеме стол, записывается в booking
export const preparedBooking = createSelector(
  [selectBooking, selectOrder],
  (bookingData, order): DeepPartial<FormBooking> => {
    if (!isEmpty(bookingData))
      return castBookingToForm(bookingData as TempBooking);
    if (!isEmpty(order)) return castOrderToForm(order as OrderSlot);
    return DEFAULT_BOOKING;
  },
);

// @ts-ignore
export const selectFormBooking = createSelector(
  [preparedBooking],
  (
    rawBookingData: DeepPartial<FormBooking>,
    client: DeepPartial<Client>,
  ): DeepPartial<FormBooking> => {
    const bookingData = { ...rawBookingData };
    const { client: bookingClient, client_id } = bookingData;
    let currentPhone = bookingClient?.phone;

    if (client) {
      currentPhone = client?.phone || currentPhone;
      bookingData.client = bookingClient ?? client ?? undefined;
      bookingData.client_id = (client_id ?? client?.client_id) as ClientId;
    }

    currentPhone = currentPhone?.replace(/^([^\D])/g, "+$1");
    bookingData.phone = formatPhoneNumberIntl(
      (currentPhone || config.defaultPhoneNumber || "+7") as E164Number,
    );
    return bookingData;
  },
);
export const disableFormFieldSelector = createSelector(
  selectDomain,
  ({ editMode }: State) => !editMode,
);

export const isEdit = createSelector(
  selectDomain,
  ({ editMode }: State) => !!editMode,
);

export const isClientEditing = createSelector(
  selectDomain,
  (state) => state.editClient,
);
