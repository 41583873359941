import { DeepPartial } from "@reduxjs/toolkit";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import type {
  BookingSlot,
  ManagerSlot,
  OrderSlot,
  Slot,
} from "models/booking.model";

import { GUEST_COMMENT_CONTEXT_MENU_LIMIT } from "../constants";
import { Client } from "../models/client.model";

export function getBookingClientCommentShort(client: Client) {
  const { comment } = client;
  if (comment) {
    return `${comment.slice(0, GUEST_COMMENT_CONTEXT_MENU_LIMIT)}...`;
  }
  return "";
}

export function getBookingStartTime(date: string, time: string): Dayjs {
  return dayjs(`${date} ${time}`, "YYYY-MM-DD HH:mm").startOf("minute");
}

export function getBookingEndTime(
  date: string,
  time: string,
  visitDuration: number,
): Dayjs {
  // FIXME: убедись, что расчеты верные, раньше здесь было visit_time
  return getBookingStartTime(date, time).add(visitDuration, "minutes");
}

export function isBookingOrder(slot: Slot | null): slot is OrderSlot {
  return slot?.slot_type === "ORDER";
}

export function isBooking(slot: Slot | null): slot is BookingSlot {
  return slot?.slot_type === "BOOKING";
}

export function getBookingId(slot: Slot | null) {
  return slot?.slot_id;
}

export function etManagerialTableId(slot: Slot | null) {
  return slot?.slot_id;
}

export function isManagerialTable(slot: Slot | null | undefined): slot is ManagerSlot {
  return slot?.slot_type === "MANAGER";
}

export function isBookingCanBeCanceled(slot: DeepPartial<Slot> | undefined) {
  // todo: refactoring hardcode
  return (
    slot?.slot_type === "BOOKING" &&
    slot.status &&
    slot.status.category !== "TERMINAL" &&
    slot.status.system_name !== "IN_HALL"
  );
}
