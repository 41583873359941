import { Notification } from "services/notification";
import type { Locales, NonEmptyArray, ShortLocales } from "types/commons";
import type { RecordType } from "types/communication";
import { type ZodTypeAny, z } from "zod";

import { DEFAULT_DELAY } from "../constants";

export const getUserLocale = () => {
  let locale = (navigator.languages || [])[0] || navigator.language || "en";
  if (locale.includes("-")) {
    locale = locale.split("-")[0];
  }
  return locale;
};
export function transformLocale(locale: ShortLocales): Locales;
export function transformLocale(locale: Locales): ShortLocales;
export function transformLocale(
  locale: ShortLocales | Locales,
): ShortLocales | Locales {
  return locale.includes("_")
    ? (locale.slice(0, 2) as ShortLocales)
    : (`${locale}_${locale.toUpperCase()}` as Locales);
}

export const capitalizeString = <S extends string>(str: S) =>
  str.replace(/./, (char) => char.toUpperCase()) as Capitalize<S>;

export const declOfNum = (num: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
  ];
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function invariant(
  condition: unknown,
  message: string,
  isNeedToNotificate?: boolean,
): asserts condition {
  if (!condition) {
    isNeedToNotificate && Notification.error(message);
    throw new Error(message);
  }
}

export const getRecordParamInfo = <T extends RecordType, Id extends number>(
  record: `${T}-${Id}` | undefined,
) =>
  record?.match(/^(?<recordType>\w+)-(?<id>\d+)$/)?.groups as
    | { recordType: `${T}`; id: `${Id}` }
    | null
    | undefined;

export const getComplexParamInfo = (value: string) =>
  value.match(/^(?<name>\w+)-(?<id>\d+)$/)?.groups;

export const validateComplexParam = <
  NS extends ZodTypeAny,
  PS extends ZodTypeAny,
>(
  param: string | undefined,
  paramNameSchema: NS,
  paramIdSchema: PS,
) =>
  z
    .string()
    .transform(getComplexParamInfo)
    .pipe(
      z.object({
        name: z.string().pipe(paramNameSchema),
        id: z.coerce.number().pipe(paramIdSchema),
      }),
    )
    .safeParse(param);

export const isNonEmptyArray = <T extends {}>(
  value: T[],
): value is NonEmptyArray<T> => Boolean(value.length);

export const formDataToObject = (data: FormData) => {
  const formDataObj: Record<string, FormDataEntryValue | FormDataEntryValue[]> =
    {};
  data.forEach((value, key, formData) => {
    const values = formData.getAll(key);
    formDataObj[key] = values.length > 1 ? values : value;
  });
  return formDataObj;
};

type AnyFunction = (...arguments_: readonly any[]) => unknown;

type DebouncedFunction<F extends AnyFunction> = (
  ...arguments_: Parameters<F>
) => ReturnType<F> | undefined;

export const debounce = <F extends AnyFunction>(
  fn: F,
  t?: number,
): DebouncedFunction<F> => {
  let timer: NodeJS.Timeout;
  return (...args: Parameters<F>) => (
    clearTimeout(timer),
    (timer = setTimeout(() => fn(...args), t ?? DEFAULT_DELAY)) as ReturnType<F>
  );
};

export const tryCatch = <R extends AnyFunction, C extends AnyFunction>(
  valueFn: R,
  catchFn?: C,
) => {
  try {
    return valueFn() as ReturnType<R>;
  } catch (e) {
    return catchFn?.() as ReturnType<C> | undefined;
  }
};
