import { ICONS, M } from "common/helpers";
import { BookingsList } from "components/BookingsList";
import { useFromProxyActions } from "features/BookingFormProxy";
import { hasBookingSelector } from "features/BookingFormProxy/selectors";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { timelineSelectors } from "features/Timeline";
import type { BookingSlot } from "models/booking.model";
import { Client } from "models/client.model";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { useIntlUtils } from "../../../../hooks/useIntlUtils";
import { ETranslations } from "../../../../types/translates";
import styles from "./TableBookingList.module.scss";

interface BookingCardDetailListProps {
  data: Array<BookingSlot>;
  onClick?: (booking: BookingSlot) => void;
}

export const BookingCardDetailList: React.VFC<BookingCardDetailListProps> = ({
  data,
  onClick,
}) => {
  const { intl } = useIntlUtils();
  const getSliderTime = useSelector(timelineSelectors.getSliderTime);

  const { current, next } = useMemo(
    () =>
      data.reduce(
        (acc, book) => {
          const startBook = M(`${book.date} ${book.time}`);
          // todo: remove hardcode
          if (
            book.status?.system_name === "IN_HALL" &&
            getSliderTime().isSameOrAfter(startBook)
          ) {
            acc.current.push(book);
            return acc;
          }

          const endBook = startBook.clone().add(book.visit_duration, "m");
          if (getSliderTime().isBetween(startBook, endBook)) {
            acc.current.push(book);
            return acc;
          }
          acc.next.push(book);
          return acc;
        },
        {
          current: [] as Array<BookingSlot>,
          next: [] as Array<BookingSlot>,
        },
      ),
    [data, getSliderTime],
  );

  const userSelectBooking = useSelector(hasBookingSelector);
  const { switchMode } = useHallSchemaActions();
  const { setBooking, resetBooking, setEditMode } = useFromProxyActions();

  const handleCardClick = useCallback(
    (booking: BookingSlot) => {
      if (onClick) {
        onClick(booking);
        return;
      }
      const { client } = booking;
      if (userSelectBooking) {
        resetBooking();
        switchMode(HallMode.TABLE_BOOKINGS_LIST);
      } else {
        setBooking({ booking, client: client as unknown as Client });
        switchMode(HallMode.TABLE_BOOKINGS_EDIT);
      }
    },
    [userSelectBooking, onClick],
  );

  const handleEditClick = useCallback(
    (booking: BookingSlot) => {
      handleCardClick(booking);
      setEditMode(true);
    },
    [handleCardClick, setEditMode],
  );

  return (
    <>
      {Boolean(current.length) && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <img alt="" src={ICONS.curBooking} />
            <span>
              {intl.formatMessage({ id: ETranslations.CURRENT_BOOKING })}
            </span>
          </div>
          <div className={styles.sectionContent}>
            <BookingsList bookings={current} allowMove />
          </div>
        </div>
      )}
      {Boolean(next.length) && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <img alt="" src={ICONS.futureBookings} />
            <span>
              {intl.formatMessage({ id: ETranslations.UPCOMING_BOOKINGS })}
            </span>
          </div>
          <div className={styles.sectionContent}>
            <BookingsList bookings={next} allowMove />
          </div>
        </div>
      )}
    </>
  );
};
