import { z } from "zod";

import { Prettify } from "./commons";

export type Locale = "en_EN" | "ru_RU";

export type EditStatusRequest = {
  status_id: number;
  is_active: boolean;
  localization?: Partial<Pick<Status, "locale" | "name" | "color">>;
};

export const StatusId = z.number().brand("StatusId");
export type StatusId = z.infer<typeof StatusId>;

const StatusTenantId = z.number().brand("StatusTenantId");
export type StatusTenantId = z.infer<typeof StatusTenantId>;

const StatusCategory = z.enum(["PRE_SERVICE", "IN_SERVICE", "TERMINAL"]);
export type StatusCategory = z.infer<typeof StatusCategory>;

const StatusSystemName = z
  .enum([
    "EXTERNAL",
    "NEW",
    "CONFIRMED",
    "CANCELED",
    "NOT_COME",
    "IN_HALL",
    "WAIT_LIST",
    "CLOSED",
  ])
  .or(z.string().brand("StatusSystemName"));
export type StatusSystemName = z.infer<typeof StatusSystemName>;

export const CommonStatus = z.object({
  id: StatusId,
  locale: z.enum(["en_EN", "ru_RU", "unknown"]),
  name: z.string(),
  step: z.number(),
  visible: z.boolean(),
  category: StatusCategory,
  color: z.string(),
  tenant_id: StatusTenantId,
  system_name: StatusSystemName,
  is_terminal: z.boolean(),
  is_extra: z.boolean(),
  is_active: z.boolean(),
});
export type Status = z.infer<typeof Status>;

export const BaseStatus = CommonStatus.extend({
  is_extra: z.literal(false),
  is_active: z.literal(true),
});
export type BaseStatus = z.infer<typeof BaseStatus>;

export const ExtraStatus = CommonStatus.extend({
  is_extra: z.literal(true),
  is_terminal: z.literal(false),
});

export const Status = z.discriminatedUnion("is_extra", [
  BaseStatus,
  ExtraStatus,
]);
export type ExtraStatus = z.infer<typeof ExtraStatus>;
export type ActiveExtraStatus = Prettify<ExtraStatus & { is_active: true }>;
