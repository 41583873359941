import { restaurantsApi } from "features/api/restaurants";
import { userApi } from "features/api/user-api";
import type { RestaurantId } from "models/booking.model";
import type { LoaderFunction } from "react-router-dom";
import { loadStore } from "storage";
import { CollapsesOption } from "ui-kit";
import { invariant } from "utils";

import type { RoleCardProps } from "../ui/Roles";

const getRestaurantNamesString = (
  restaurantIds: RestaurantId[],
  restaurantNamesById: Record<RestaurantId, string>,
) =>
  restaurantIds.reduce(
    (result, id) =>
      result
        ? result + `, ${restaurantNamesById[id]}`
        : restaurantNamesById[id],
    "",
  );

export const loader = (async ({ request }) => {
  const store = await loadStore();

  const getRestaurantsPromise = store.dispatch(
    restaurantsApi.endpoints.fetchAllRestaurants.initiate(),
  );

  const getRolesPromise = store.dispatch(
    userApi.endpoints.getAllGroups.initiate(),
  );

  const getUsersPromise = store.dispatch(
    userApi.endpoints.getAllUsers.initiate(),
  );

  const filter = new URL(request.url).searchParams.get("q")?.toLowerCase();

  const loaderData = await Promise.all([
    getRestaurantsPromise,
    getRolesPromise,
    getUsersPromise,
  ]).then((data) => {
    // Выбрасываем исключение, если не удалось загрузить хоть что-то
    invariant(
      data.every((v) => v.data),
      JSON.stringify(data.find((v) => !v.data)?.error),
    );

    const restaurantNamesById = data[0].data!.reduce<
      Record<RestaurantId, string>
    >((acc, r) => ((acc[r.restaurant_id] = r.restaurant_name), acc), {});

    const initialOptions = data[1].data!.reduce(
      (acc, role) => (
        acc.result.push({
          title: role.group_translation_name || role.group_name,
          collapseData: new Array(),
        }),
        acc.roleIndexMap.set(role.group_name, acc.result.length),
        acc
      ),
      {
        result: new Array<CollapsesOption<RoleCardProps>>(),
        roleIndexMap: new Map<string, number>(),
      },
    );
    const users = data[2].data!.reduce((acc, user) => {
      const currentRoleIndex = acc.roleIndexMap.get(user.user_group.group_name);
      invariant(
        currentRoleIndex !== undefined,
        `Unknown role ${user.user_group.group_name}`,
      );
      // Если есть фильтр, то добавить в массив лишь тех пользователей, которые соответствуют фильтру
      (!filter || user.username.toLowerCase().includes(filter)) &&
        acc.result[currentRoleIndex].collapseData.push({
          userId: user.user_serial,
          userName: user.username,
          restaurantNames: getRestaurantNamesString(
            user.active_in_restaurants,
            restaurantNamesById,
          ),
          phone: undefined,
        });

      return acc;
    }, initialOptions);

    return users.result;
  });

  return loaderData;
}) satisfies LoaderFunction;
