import { Button, ICONS } from "..";
import styles from "./ModeSwitch.module.scss";
import cn from "classnames";
import { useCallback, useEffect } from "react";
import { useLocalStorage } from "react-use";

export function ModeSwitch({ className }: { className?: string }) {
  const [mode, setMode] = useLocalStorage<string | undefined>("ui:mode");

  const setLight = useCallback(() => {
    setMode("light");
  }, [setMode]);

  const setDark = useCallback(() => {
    setMode("dark");
  }, [setMode]);

  useEffect(() => {
    if (!mode) return;
    document.documentElement.dataset.mode = mode;
  }, [mode]);

  return (
    <div className={cn(className, styles.root)}>
      <Button className={styles.dark} variant="phantom" onClick={setLight}>
        <ICONS.DarkTheme />
      </Button>
      <Button className={styles.light} variant="phantom" onClick={setDark}>
        <ICONS.LightTheme />
      </Button>
    </div>
  );
}
