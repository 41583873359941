import { dateSelector } from "features/AppContex";
import { useBookingsList } from "features/api/bookings-api";
import {
  getActiveStatuses,
  useAllStatuses,
} from "features/api/dictionaries-api";
import { useSelector } from "react-redux";
import { TUseBookingList } from "types/booking";

export function useTablesBookings(
  tableId: number | undefined,
  isSkip: boolean,
  search?: string,
  isActiveStatuses = false,
) {
  const currentDate = useSelector(dateSelector);
  const { statuses: allStatuses } = useAllStatuses();
  const activeStatuses = getActiveStatuses(allStatuses);
  const allStatusesSystemName = allStatuses.map((status) => status.system_name);
  const activeStatusesSystemName = activeStatuses.map(
    (status) => status.system_name,
  );
  const formatDate = currentDate.format("YYYY-MM-DD");

  const bookingPayload: TUseBookingList = {
    search,
    forTables: tableId ? [tableId] : [],
    fromDate: formatDate,
    toDate: formatDate,
    includeStatuses: isActiveStatuses
      ? activeStatusesSystemName
      : allStatusesSystemName,
    isSkip,
  };

  return useBookingsList(bookingPayload);
}
