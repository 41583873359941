import { CalendarOptions } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayjs from "dayjs";
import { restaurantSelector } from "features/AppContex";
import {
  useEditShiftMutation,
  useFetchWeekShiftsQuery,
  useLazyFetchShiftQuery,
} from "features/api/shifts";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { TNullable } from "types/commons";
import { WeekShift } from "types/shift";
import { ETranslations } from "types/translates";

import { CreateShiftsModal } from "../Modal/CreateShiftsModal/CreateShiftsModal";
import { InfoShiftsModal } from "../Modal/InfoModal/InfoShiftsModal";
import { TPeriod } from "../Shifts";
import { prepareShiftsForCalendar } from "../utils";
import "./schedulerCssVariables.scss";
import styles from "./style.module.scss";

interface Props {
  schedulerRef: MutableRefObject<FullCalendar | null>;
  period: TPeriod;
}

export type TModalOpen = TNullable<"INFO" | "CREATE">;

export type ShiftSelectedDates = {
  start: string;
  end: string;
};

type ChangeTimeShift =
  | CalendarOptions["eventDrop"]
  | CalendarOptions["eventResize"];

const renderEventContent: CalendarOptions["eventContent"] = (eventInfo) => {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
};

export const ShiftsCalendar: FC<Props> = ({ schedulerRef, period }) => {
  const restaurant = useSelector(restaurantSelector);
  const [modalOpen, setModalOpen] = useState<TModalOpen>(null);
  const [startDate, setStartDate] = useState<ShiftSelectedDates>({
    start: dayjs.tz().format(),
    end: dayjs.tz().format(),
  });

  const intl = useIntl();

  const [editShift] = useEditShiftMutation();
  const [activeShiftId, setActiveShiftId] = useState<TNullable<number>>(null);
  const [activeShiftName, setActiveShiftName] = useState("");
  const [fetchShift] = useLazyFetchShiftQuery();

  const { data } = useFetchWeekShiftsQuery({
    restaurantId: restaurant.restaurant_id,
    start_date: period.start.format("YYYY-MM-DD"),
    end_date: period.end.format("YYYY-MM-DD"),
  });

  const handleDateSelect: CalendarOptions["select"] = (selectInfo) => {
    setStartDate({
      start: selectInfo.startStr,
      end: selectInfo.endStr,
    });
    setModalOpen("CREATE");
  };

  const handleEventClick: CalendarOptions["eventClick"] = (clickInfo) => {
    // Получаем оригинальный shift id
    const shiftId = clickInfo.event.id.split(":")[0];
    setActiveShiftId(Number(shiftId));
    setActiveShiftName(clickInfo.event.title);
    setModalOpen("INFO");
  };

  const modalOnClose = useCallback(() => setModalOpen(null), []);

  const changeShiftTimeHandler: ChangeTimeShift = useCallback(async (e) => {
    const end = e.event.endStr;
    const start = e.event.startStr;
    // смена идет в след день
    const shiftGoesToNextDay = dayjs(start).get("hours") === 0;
    const id = Number(e.event.id.split(":")[0]);
    const { data: shift } = await fetchShift(id);
    if (!shift) return;
    const shiftPayload = {
      id,
      data: {
        ...shift,
        // проверка если у нас смена начинается в 00:00 то тогда не берем дату начала
        // так если шифт у нас начинается к примеру 18:00 и заканчивается 03:20 след дня
        // данная библиотека воспринимает это как два шифта
        ...(!shiftGoesToNextDay && {
          start_active_time: dayjs(start).format("HH:mm:ss"),
        }),
        end_active_time: dayjs(end).format("HH:mm:ss"),
      },
    };
    editShift(shiftPayload);
  }, []);

  const events = useMemo(() => {
    if (data) {
      return prepareShiftsForCalendar(data);
    }
    return [];
  }, [data]);

  const eventAllow: CalendarOptions["eventAllow"] = useCallback(
    (dropInfo, draggedEvent) => {
      const currentDateStart = dayjs(draggedEvent?.startStr);
      const dropDateStart = dayjs(dropInfo.startStr);
      const dropDateEnd = dayjs(dropInfo.endStr);

      //Разрешаем переносить шифт в пределах одного дня
      if (dropDateStart.day() !== currentDateStart.day()) return false;

      const duration = dropDateEnd.diff(currentDateStart, "hour", true);

      // Шифт не может иметь продолжительность больше 24х часов
      return duration <= 24;
    },
    [],
  );

  const selectAllow: CalendarOptions["selectAllow"] = useCallback(
    (selectInfo) => {
      const start = dayjs(selectInfo.startStr);
      const end = dayjs(selectInfo.endStr);
      const duration = end.diff(start, "hour", true);

      // Шифт не может иметь продолжительность больше 24х часов
      return duration <= 24;
    },
    [],
  );

  const eventOverlap: CalendarOptions["eventOverlap"] = useCallback(
    (stillEvent, movingEvent) => {
      const { places: currentPlaces } = stillEvent.extendedProps as WeekShift;
      const { places: movingPlaces } = movingEvent?.extendedProps as WeekShift;
      const isDifferentPlaces = currentPlaces.some((day) =>
        movingPlaces.includes(day),
      );

      return !isDifferentPlaces;
    },
    [],
  );

  return (
    <div className="calendar">
      <FullCalendar
        allDaySlot={false}
        dayHeaderClassNames={styles.cellText}
        dayHeaderFormat={({ date }) => dayjs(date.marker).format("dd D")}
        eventAllow={eventAllow}
        eventClassNames={styles.eventContent}
        eventClick={handleEventClick}
        eventContent={renderEventContent} // custom render function
        eventDrop={changeShiftTimeHandler as CalendarOptions["eventDrop"]}
        eventOverlap={eventOverlap}
        eventResize={changeShiftTimeHandler as CalendarOptions["eventResize"]}
        events={events} // alternatively, use the `events` setting to fetch from a feed
        timeZoneParam={restaurant.timezone}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          hourCycle: "h23",
        }}
        firstDay={1}
        headerToolbar={{
          left: "",
          center: "",
          right: "",
        }}
        height={"100%"}
        initialDate={period.start.toDate()}
        initialView="timeGridWeek"
        nextDayThreshold="00:04:00"
        plugins={[timeGridPlugin, interactionPlugin]}
        ref={schedulerRef}
        select={handleDateSelect}
        selectAllow={selectAllow}
        selectOverlap={false}
        slotDuration={{ minutes: 15 }}
        slotLabelClassNames={styles.cellText}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          hourCycle: "h23",
        }}
        slotLaneClassNames={styles.cell}
        editable
        handleWindowResize
        nowIndicator
        selectable
        weekends
      />
      <InfoShiftsModal
        isOpen={modalOpen === "INFO" && Boolean(activeShiftId)}
        shiftId={activeShiftId as number}
        title={activeShiftName}
        onClose={modalOnClose}
      />
      <CreateShiftsModal
        isOpen={modalOpen === "CREATE"}
        setActiveShiftId={setActiveShiftId}
        setActiveShiftName={setActiveShiftName}
        setModalOpen={setModalOpen}
        startDate={startDate}
        title={intl.formatMessage({ id: ETranslations.CREATE_SHIFT })}
        onClose={modalOnClose}
      />
    </div>
  );
};
