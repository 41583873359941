import styles from "./FieldError.module.scss";

export const FieldError = ({ error }: { error: string | string[] }) =>
  Array.isArray(error) ? (
    <ul className={styles.errorList}>
      {Array.from(new Set(error)).map((err) => (
        <li key={String(err)}>
          <strong className={styles.error}>{err}</strong>
        </li>
      ))}
    </ul>
  ) : (
    <strong className={styles.error}>{error}</strong>
  );
