import cn from "classnames";
import { ICONS } from "common/helpers";
import { config } from "config";
import { dictionariesApi, useTranslates } from "features/api/dictionaries-api";
import { useUserLocale } from "hooks/useUserLocale";
import { useDispatch } from "react-redux";
import {
  Form,
  type Location,
  useActionData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Button, Card, FieldError, Input } from "ui-kit";

import { ETranslations } from "../../../types/translates";
import type { action } from "../api/action";
import { AUTH_INTENT } from "../constants";
import styles from "./Auth.module.scss";
import { InputPassword } from "./InputPassword";
import { LocaleControls } from "./LocaleControls";

const Clear = () => {
  const dispatch = useDispatch();
  dispatch(dictionariesApi.util.invalidateTags(["Dicts"]));
  return null;
};

export const Auth = () => {
  const { fieldErrors, formErrors } = useActionData<typeof action>() || {};
  const { userLocale, setUserLocale } = useUserLocale();
  const { translates } = useTranslates(userLocale);
  const shouldConfirmPassword = false;

  const { state } = useLocation() as Location<{ hostessFrom?: string } | null>;

  /*   const onLogin = async () => {
    const { login, password } = authState;
    if (!/[0-9a-z_]/g.test(login)) {
      setAuthState((prev) => ({ ...prev, isValid: false }));
    } else {
      await AuthService.login({ login, password, locale: userLocale }).then(
        () =>
          setAuthState((prev) => ({
            ...prev,
            isValid: true,
            loginSuccess: true,
          })),
        () => setAuthState((prev) => ({ ...prev, isValid: false })),
      );
    }
  };

  if (authState.loginSuccess) {
    global?.channels?.auth?.cb && global.channels.auth.cb(true);
    navigate("/dashboard");
  } */

  const BRAND_LOGO = ICONS[
    `${config.BRAND}_AUTH_LOGO` as keyof typeof ICONS
  ] as string;
  const BRAND_BG =
    (ICONS[`${config.BRAND}_BG` as keyof typeof ICONS] as string) ||
    ICONS.WRF_BG;

  return (
    <main className={styles.auth}>
      <header
        className={styles.header}
        style={{ "--brand-bg": `url(${BRAND_BG})` }}
      >
        {BRAND_LOGO && (
          <h1>
            <img alt={config.BRAND} src={BRAND_LOGO} />
          </h1>
        )}
        <p>{translates[ETranslations.AUTH_SYSTEM_NAME]}</p>
      </header>
      <Card>
        <Card.Header
          controls={
            config.hasMultiLanguage && (
              <LocaleControls onLocaleChange={setUserLocale} />
            )
          }
          title={
            translates[
              shouldConfirmPassword
                ? ETranslations.AUTH_SYSTEM_CONFIRM
                : ETranslations.AUTH_SYSTEM_ENTRY
            ]
          }
        />
        <Form className={styles.authForm} method="POST">
          {state?.hostessFrom && (
            <input type="hidden" name="redirectTo" value={state.hostessFrom} />
          )}
          <input type="hidden" name="locale" value={userLocale} />
          <Input
            className={styles.labeled}
            inputClassName={styles.input}
            label={translates[ETranslations.AUTH_LOGIN]}
            name="login"
            type="text"
            autoFocus
            autoComplete="username"
            error={fieldErrors?.login}
            invalid={Boolean(formErrors)}
          />
          <InputPassword
            className={styles.labeled}
            inputClassName={styles.input}
            label={
              translates[
                shouldConfirmPassword
                  ? ETranslations.AUTH_CREATE_PASSWORD
                  : ETranslations.AUTH_PASSWORD
              ]
            }
            name="password"
            invalid={Boolean(fieldErrors?.confirmPassword || formErrors)}
            autoComplete={
              shouldConfirmPassword ? "new-password" : "current-password"
            }
            error={fieldErrors?.password}
          />
          {shouldConfirmPassword && (
            <InputPassword
              className={styles.labeled}
              inputClassName={styles.input}
              label={translates[ETranslations.AUTH_CONFIRM_PASSWORD]}
              name="confirmPassword"
              invalid={Boolean(formErrors)}
              autoComplete="new-password"
              error={fieldErrors?.confirmPassword}
            />
          )}

          <Button
            variant="primary"
            type="submit"
            name="intent"
            value={
              shouldConfirmPassword ? AUTH_INTENT.CONFIRM : AUTH_INTENT.ENTRY
            }
          >
            {translates[ETranslations.AUTH_SING_IN]}
          </Button>
          {formErrors && <FieldError error={formErrors} />}
        </Form>
      </Card>
    </main>
  );
};
