import cn from "classnames";
import type { MouseEventHandler, ReactNode } from "react";

import styles from "./DataSection.module.scss";

const DataSectionRoot = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <dl className={cn(styles.dataSection, className)}>{children}</dl>;

const DataSectionItem = ({
  label,
  children,
  onClick,
  className,
  labelClassName,
  valueClassName,
}: {
  label: ReactNode;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
}) => (
  <div className={cn(styles.item, className)}>
    <dt className={labelClassName}>{label}</dt>
    <dd className={valueClassName}>
      {onClick ? (
        <button type="button" onClick={onClick}>
          {children}
        </button>
      ) : (
        children
      )}
    </dd>
  </div>
);

export const DataSection = { Root: DataSectionRoot, Item: DataSectionItem };
