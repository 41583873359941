import cn from "classnames";
import { emptyStringIfNull, getFullName, getMaskPhone } from "common/helpers";
import { ClientInfoPopup } from "components/BookingInfoPopup";
import { TagsById } from "components/Tags";
import { config } from "config";
import { restaurantSelector } from "features/AppContex";
import { useGuestListActions } from "features/GuestsList";
import {
  guestListFilter,
  isGuestAttachMode,
  selectedTags,
} from "features/GuestsList/selectors";
import {
  useFetchGuestsPageQuery,
  useGuestsList,
  useLazyFetchGuestsPageQuery,
} from "features/api/guest-api";
import { useClientTagsOptions } from "features/api/tags";
import type { Client } from "models/client.model";
import { forwardRef, memo, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import type { ClientsFilter } from "services/clients.service";
import type { ClientId } from "types/client";
import {
  Button,
  Card,
  ICONS,
  Input,
  LinkButton,
  SelectCheckbox,
  Spinner,
} from "ui-kit";

import { useIntlUtils } from "../../hooks/useIntlUtils";
import { ETranslations } from "../../types/translates";
import { TAGS_TITLES } from "./../../constants";
import styles from "./GuestList.module.scss";

const GuestItem = memo(
  forwardRef<
    HTMLDivElement,
    {
      client_id: number;
      vip: boolean | undefined;
      phone: string;
      fullName: string;
      tagsIdsString: string | undefined;
    }
  >(({ client_id, vip, phone, fullName, tagsIdsString }, ref) => {
    return (
      <article className={styles.guestCard} ref={ref}>
        <NavLink
          to={String(client_id)}
          className={({ isActive, isPending }) =>
            cn(styles.guestInfo, {
              [styles.active]: isActive,
              [styles.pending]: isPending,
            })
          }
        >
          <h3 className={styles.fullName}>{fullName}</h3>
          <p className={styles.phone}>
            <ICONS.USER_PHONE />
            <span>{phone}</span>
          </p>
          <div className={styles.tags}>
            {vip && <ICONS.VipSign />}
            {tagsIdsString && (
              <TagsById tagsIDs={tagsIdsString.split(",") as `${number}`[]} />
            )}
          </div>
        </NavLink>
        <ClientInfoPopup clientId={client_id as ClientId} placement="auto">
          <Button
            className={styles.guestDetail}
            type="button"
            variant="phantom"
          >
            <ICONS.Question />
          </Button>
        </ClientInfoPopup>
      </article>
    );
  }),
);

const GuestInfiniteList = ({ initialGuests }: { initialGuests: Client[] }) => {
  const { formatMessage } = useIntl();
  const [guestListState, setGuestListState] = useState({
    guests: initialGuests,
    nextPage: 1,
    hasMoreGuests: true,
  });
  const [fetchNextPage, { isFetching }] = useLazyFetchGuestsPageQuery();
  const observerRef = useRef<HTMLDivElement>(null);
  const loadMoreButtonRef = useRef<HTMLButtonElement>(null);
  const filter = useSelector(guestListFilter);
  const updatedFilter: ClientsFilter = {
    ...filter,
    offset: filter.count * guestListState.nextPage,
  };
  useEffect(() => {
    const observerTarget = observerRef.current;
    const loadMoreButton = loadMoreButtonRef.current;
    if (!observerTarget || !loadMoreButton) return;

    const observer = new IntersectionObserver((entries) => {
      // Когда список загружается слишком быстро или загружен до конца, то entries будет только 1 - button
      if (entries[0].isIntersecting || entries[1]?.isIntersecting) {
        !isFetching &&
          guestListState.hasMoreGuests &&
          fetchNextPage(updatedFilter).then((data) => {
            if (data.data) {
              setGuestListState((prev) => ({
                guests: prev.guests.concat(data.data!),
                nextPage: prev.nextPage + 1,
                hasMoreGuests: Boolean(data.data?.length),
              }));
            }
          });
      }
    });

    observer.observe(observerTarget);
    observer.observe(loadMoreButton);
    return () => observer.disconnect();
  }, [guestListState, updatedFilter]);
  return (
    <Card.Content className={styles.infiniteList}>
      {guestListState.guests.map((g, index) => (
        <GuestItem
          key={g.client_id}
          client_id={g.client_id}
          vip={config.vipSign && g.vip}
          phone={g.phone ? getMaskPhone(g.phone) : "N/A"}
          fullName={getFullName(g.name, g.middle_name, g.surname) || "N/A"}
          tagsIdsString={g.tags?.length ? g.tags.join() : undefined}
          ref={
            index ===
            Math.floor(
              Math.max(
                guestListState.guests.length / 2,
                guestListState.guests.length - updatedFilter.count,
              ),
            )
              ? observerRef
              : undefined
          }
        />
      ))}
      {guestListState.guests.length >= filter.count && (
        <Button
          variant="secondary"
          className={styles.loadMoreButton}
          ref={loadMoreButtonRef}
          disabled={!guestListState.hasMoreGuests}
          onClick={() => {
            !isFetching &&
              guestListState.hasMoreGuests &&
              fetchNextPage(updatedFilter).then((data) => {
                if (data.data) {
                  setGuestListState((prev) => ({
                    guests: prev.guests.concat(data.data!),
                    nextPage: prev.nextPage + 1,
                    hasMoreGuests: Boolean(data.data?.length),
                  }));
                }
              });
          }}
        >
          {formatMessage({
            id: isFetching
              ? ETranslations.LOADING
              : guestListState.hasMoreGuests
                ? ETranslations.LOAD_MORE_GUESTS
                : ETranslations.NO_MORE_GUESTS,
          })}
        </Button>
      )}
    </Card.Content>
  );
};

export const GuestList = ({ hideOnTablet }: { hideOnTablet: boolean }) => {
  const restaurantId = useSelector(restaurantSelector).restaurant_id;
  const tagsOptions = useClientTagsOptions(restaurantId);
  const userSelectedTags = useSelector(selectedTags);
  const { getIntlCreatingOf, getIntlChooseEntity, isRussianLocale, intl } =
    useIntlUtils();
  const filter = useSelector(guestListFilter);
  const { updateFilter } = useGuestListActions();
  const { data, fulfilledTimeStamp } = useFetchGuestsPageQuery(filter);

  const handleOnInputTerm = (value: string) => {
    updateFilter({ term: value || undefined });
  };
  const handleTagsChange = (tags: { value: number; label: string }[] = []) =>
    updateFilter({ tags: tags.map((it) => it.value) });

  const getFromFilter = () =>
    tagsOptions.filter((it) => userSelectedTags?.includes(it.value));

  return (
    <>
      {!data ? (
        <Spinner />
      ) : (
        <Card className={cn({ [styles.hide]: hideOnTablet })}>
          <Card.Header
            controls={
              <LinkButton
                variant="primary"
                className={styles.createGuestButton}
                to="create"
              >
                {getIntlCreatingOf(
                  isRussianLocale
                    ? ETranslations.PLURAL_GUESTS_ALT
                    : ETranslations.PLURAL_GUEST,
                )}
              </LinkButton>
            }
            title={intl.formatMessage({ id: ETranslations.GUEST_LIST })}
          >
            <div className={styles.subHeader}>
              <SelectCheckbox
                className={styles.filter}
                options={tagsOptions}
                placeholder={getIntlChooseEntity(ETranslations.PLURAL_TAGS_NOM)}
                titles={TAGS_TITLES}
                value={getFromFilter()}
                // @ts-ignore
                onChange={handleTagsChange}
              />

              <Input.Search
                className={styles.filter}
                onSearch={handleOnInputTerm}
              />
            </div>
          </Card.Header>
          {/* ключ, чтобы обнулять стейт при изменении начальных данных, в целом можно и другой ключ */}
          <GuestInfiniteList initialGuests={data} key={fulfilledTimeStamp} />
        </Card>
      )}
    </>
  );
};
