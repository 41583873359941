import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Card } from "ui-kit";

import GuestForm from "./GuestForm";

export const GuestFormContainer = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <Card onClose={() => navigate("..")}>
      <Card.Header title={formatMessage({ id: ETranslations.GUEST_CARD })} />
      <Card.Content noPadding>
        <GuestForm />
      </Card.Content>
    </Card>
  );
};
