import dayjs from "dayjs";
import { useApplicationContextActions } from "features/AppContex";
import { useTimelineActions } from "features/Timeline";
import { useField } from "react-final-form";
import { useUpdateEffect } from "react-use";



import { Option } from "../../../../models/common";


const useChoiceDataChips = ({ value, onChange, disabled }: any) => {
  const {
    input: { value: dateField },
  } = useField<string | null>("date", { allowNull: true });

  const {
    input: { onChange: changeTime, value: timeValue },
  } = useField<Option<string> | null>("time", { allowNull: true });
  const { setIsActualTime, setTimestamp } = useTimelineActions();
  const { setDate } = useApplicationContextActions();

  // Хук для синхронизации глобальной даты с датой формы
  useUpdateEffect(() => {
    if (disabled) {
      return;
    } else {
      const valueDate = dayjs(value).startOf("day");
      const selectedDate = dayjs(dateField).toDate();
      const diffDays = dayjs(selectedDate).diff(valueDate, "day");

      // Устанавливаем актуальное время для следующего дня. Иначе установится первое доступное на новый день.
      if (typeof timeValue?.value === "number") {
        const time = dayjs(timeValue.value);
        const timestamp = time.add(diffDays, "day").valueOf();
        changeTime({ ...timeValue, value: timestamp });
      }

      if (valueDate.isSame(selectedDate)) return;
      onChange(dayjs(selectedDate).toDate());
    }
  }, [dayjs(dateField).valueOf()]);

  const datePickerHandler = (newDate: string) => {
    const now = dayjs(value);
    const selectedDate = dayjs(newDate);
    const diffDays = selectedDate.diff(now, "days");

    const newTimestamp = dayjs(timeValue?.value).add(diffDays, "day");

    setDate(selectedDate.toDate().toISOString());
    setTimestamp(newTimestamp.valueOf());
    setIsActualTime(false);
  };

  return {
    datePickerHandler,
    dateField,
  };
};

export default useChoiceDataChips;