import styles from "./Sidebar.module.scss";
import { restaurantsSelector } from "features/AppContex";
import {
  DialogFilterKind,
  DialogStatus,
  useDialogs,
} from "features/Dialogs/SDK";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const ARRAY_EMPTY: Array<any> = [];

export const UnansweredCounter = () => {
  const restaurants = useSelector(restaurantsSelector);
  const partners = useMemo(
    () => restaurants.map((r) => String(r.restaurant_id)),
    [restaurants],
  );

  const filters = useMemo(
    () => ({
      partners,
      customerTypes: ARRAY_EMPTY,
      projects: ARRAY_EMPTY,
      taskTypes: ARRAY_EMPTY,
      kind: DialogFilterKind.ALL,
      status: DialogStatus.ALL,
    }),
    [partners],
  );

  const { dialogs } = useDialogs(filters);

  const unansweredCount = useMemo(
    () =>
      dialogs?.reduce(
        (count, dialog) =>
          count +
          +(dialog.unanswered_count && dialog.status !== DialogStatus.CLOSED),
        0,
      ),
    [dialogs],
  );

  return unansweredCount ? (
    <span className={styles.counter}>{unansweredCount}</span>
  ) : null;
};
