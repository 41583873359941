import parsePhoneNumber from "libphonenumber-js";
import { Client } from "types/client";

import { Client as OldClient } from "../../../models/client.model";

export function requiredField(value: any, message?: string) {
  return value ? undefined : message || "Required";
}

export function validateClient(
  value: Client | OldClient | undefined | null,
  isWithPhone: boolean,
  message?: string,
) {
  return (
    // Если есть клиент (с телефоном) и у него непустая фамилия, то валидно.
    // Или есть контакт (без телефона) и у него непустая фамилия и не пустое имя, то валидно
    !(value && value.surname?.trim() && (isWithPhone || value.name?.trim())) &&
    (message || "Name fields are not valid")
  );
}

export const isRealClient = (client: Client | OldClient | null | undefined) =>
  client && Boolean(client.client_id);

export function phoneValidInput(value: string, message?: string) {
  return value && parsePhoneNumber(value)?.isValid()
    ? undefined
    : message || "Phone not valid format";
}
