import cn from "classnames";
import { type FormEvent, type ReactNode, useRef, useState } from "react";

import styles from "./FormStepper.module.scss";

export const FormStepper = ({
  stepsInfo,
  startFromStep,
  idPrefix,
  children,
}: {
  // valid имеет три состояния: true = валидный, false = инвалидный, undefined = не проверялся
  stepsInfo: {
    title: string;
    description?: string;
    valid?: boolean;
  }[];
  startFromStep?: number;
  idPrefix: string;
  children: ReactNode;
}) => {
  const [activeStep, setActiveStep] = useState(startFromStep || 1);
  const hasNextStep = activeStep < stepsInfo.length;
  const handleRadioChange = (e: FormEvent<HTMLUListElement>) => {
    const el = e.target;
    if (el instanceof HTMLInputElement) {
      const newStep = Number(el.id?.replace(/.+-(\d+)$/, "$1"));
      newStep && setActiveStep(newStep);
    }
  };
  return (
    <nav>
      <ul className={styles.stepList} onChange={handleRadioChange}>
        {stepsInfo.map((info, index) => (
          <li
            key={info.title}
            className={cn(
              styles.stepItem,
              info.valid && styles.valid,
              info.valid === false && styles.invalid,
            )}
          >
            <input
              id={`${idPrefix}-${index + 1}`}
              type="radio"
              name={idPrefix}
              checked={index + 1 === activeStep}
              hidden
            />
            <label
              className={styles.label}
              htmlFor={`${idPrefix}-${index + 1}`}
            >
              <strong className={styles.stepTitle}>{info.title}</strong>
              {info.description && (
                <span className={styles.stepDescription}>
                  {info.description}
                </span>
              )}
            </label>
          </li>
        ))}
      </ul>

      {hasNextStep ? (
        <button onClick={() => setActiveStep((prev) => prev + 1)}>Next</button>
      ) : (
        children
      )}
    </nav>
  );
};
