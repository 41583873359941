import { applicationContextActions } from "features/AppContex";
import { localResourcesApi } from "features/api/dictionaries-api";
import type { GrantedUser } from "models/user.model";
import { type ActionFunction, redirect } from "react-router-dom";
import { type AuthErrorCause, AuthService } from "services/auth.service";
import { loadStore } from "storage";
import { ETranslations } from "types/translates";
import { commonFormErrorMap, transformLocale, validateLocale } from "utils";

import { AuthFormSchema } from "../model/schema";

export const action = (async ({ request }) => {
  const formData = await request.formData();
  const locale = transformLocale(validateLocale(formData.get("locale")));
  const formDataObj = Object.fromEntries(formData);
  const parsedData = AuthFormSchema.safeParse(formDataObj, {
    errorMap: commonFormErrorMap,
  });
  const store = await loadStore();
  const translates = await store
    .dispatch(localResourcesApi.endpoints.fetchTranslates.initiate(locale))
    .then((data) => data.data);

  const validationErrors = parsedData.error?.flatten(({ message }) =>
    translates && message in translates
      ? translates[message as ETranslations]
      : message,
  );
  const authError =
    parsedData.success &&
    (await AuthService.login({
      login: parsedData.data.login,
      password: parsedData.data.password,
      locale: parsedData.data.locale,
    })
      .then(
        (user) => (
          store.dispatch(
            applicationContextActions.setCurrentUser(
              user as unknown as GrantedUser,
            ),
          ),
          localStorage.setItem("user", JSON.stringify(user))
        ),
      )
      .catch((e: { cause: AuthErrorCause }) => e));

  const translatedAuthError =
    authError &&
    "cause" in authError &&
    ((authError.cause.code === 10000
      ? translates?.["AUTH_INCORRECT_CREDENTIALS"]
      : translates?.["ERROR_SOMETHING_WENT_WRONG"]) ||
      "Error");

  const errors = parsedData.success
    ? { formErrors: translatedAuthError, fieldErrors: undefined }
    : validationErrors!;

  const isLoginSuccess = parsedData.success && !authError;

  isLoginSuccess && global?.channels?.auth?.cb && global.channels.auth.cb(true);

  return isLoginSuccess
    ? redirect(parsedData.data.redirectTo || "/dashboard")
    : { shouldConfirmPassword: false, ...errors };
}) satisfies ActionFunction;
