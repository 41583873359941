import { ClientId } from "types/client";
import { z } from "zod";

import { TDictionary } from "../containers/Guests/GuestCard";
import { TNullable } from "../types/commons";
import { Loyalty } from "./booking.model";
import { CRMTag } from "./tags.model";

export interface ClientBookingInfo {
  status_id?: number;
  status_name?: string;
  count?: number;
  last_booking_date?: string; // Last visit: 27.02.2022
  last_booking_time?: string;
  last_location?: string; // Last location: Place
}

export class Client {
  constructor(
    public client_id: number,
    public name: string,
    public surname: string,
    public phone: string,
    public email: string | null | undefined,
    public user_id: number,
    public grade_name: string,
    public grade_color: string | null,
    public tags: number[],
    public loyalty_lvl: Loyalty,
    public average_visits: number,
    public time: string,
    public birthday: string,
    public middle_name?: string,
    public comment?: string,
    public client_photo?: string,
    public sex?: TDictionary,
    public work_phone?: string,
    public alt_email?: string,
    public vip?: boolean,
    public salutation_id?: number,
    public salutation?: TDictionary,
    public last_visit?: string,
    public last_booking_time?: string,
    public last_location?: string,
    public visit_count?: number,
    public cancel_count?: number,
    public not_come_count?: number,
    public avg_visit?: TNullable<number>,
    public avg_transaction?: TNullable<number>,
    public total_purchase?: TNullable<number>,
    public editClient?: boolean,
  ) {}
}

export interface ClientWithFullName extends Client {
  full_name: string;
}

export class ClientShort {
  constructor(
    public client_id: number,
    public name: string,
    public surname: string,
    public loyalty_lvl: string,
    public tags: number[],
    public grade_color: string | null,
    public grade_name: string,
  ) {}
}

export class ClientResponse {
  constructor(
    public client_id: number,
    public card_number: string,
  ) {}
}

export const ClientReviews = z
  .object({
    place: z.string(),
    review: z.string(),
    assessment: z.number().min(0).max(5),
    clientName: z.string(),
    clientSurname: z.string(),
    avatar: z.string(),
    publish: z.boolean(),
    improve: z.string().array(),
    user_id: ClientId,
    time_key: z.string().datetime({ offset: true }),
  })
  .array();
export type ClientReviews = z.infer<typeof ClientReviews>;

export const ClientsTags = z
  .object({
    clients_tags: z
      .object({
        tags: CRMTag.array(),
        client_id: ClientId,
      })
      .array(),
  })
  .transform((tagsObj) =>
    tagsObj.clients_tags.reduce<Record<`${ClientId}` | ClientId, CRMTag[]>>(
      (result, clientTags) => (
        (result[clientTags.client_id] = clientTags.tags), result
      ),
      {},
    ),
  );
export type ClientsTags = z.infer<typeof ClientsTags>;
export type ClientsTagsDTO = z.input<typeof ClientsTags>;
