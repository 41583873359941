import EachTagGroup from "components/EachTagGroup/EachTagGroup";
import { CreateGroupTagsModal } from "components/EachTagGroup/Modals/CreateGroupTagsModal";
import { restaurantSelector } from "features/AppContex";
import { useGetNewTagsQuery } from "features/api/tags-api";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import type { TagGroup } from "types/tag";
import { ETranslations } from "types/translates";
import { Button, RadioButton, Spinner } from "ui-kit";

import "../settings.scss";

export default function TagsPage() {
  const [selectedTag, setSelectedTag] =
    useState<TagGroup["destination"]>("BOOKING");
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const restaurant = useSelector(restaurantSelector);
  const { data, isLoading } = useGetNewTagsQuery({
    destination: selectedTag,
    resturant_id: `${restaurant.restaurant_id}`,
  });
  const intl = useIntl();
  const onTogleOpenCreateModal = () =>
    setIsOpenCreateModal((prevState) => !prevState);
  // FIXME: проверка ролей
  const isHostess = false;
  const isAdminOfRest = true;

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="access_rules sections include-table">
      <div className="tags_button">
        <RadioButton value={selectedTag} onChange={setSelectedTag}>
          <RadioButton.Button value="BOOKING">
            {intl.formatMessage({ id: ETranslations.PLURAL_BOOKING })}
          </RadioButton.Button>
          <RadioButton.Button value="CLIENT">
            {intl.formatMessage({ id: ETranslations.PLURAL_GUEST })}
          </RadioButton.Button>
        </RadioButton>
        <div>
          {!isHostess && (
            <Button variant="primary" onClick={onTogleOpenCreateModal}>
              {intl.formatMessage({ id: ETranslations.CREATE_GROUP })}
            </Button>
          )}
        </div>
      </div>
      <div className="access_rules_box">
        {data?.map((eachGroup: TagGroup) => (
          <EachTagGroup
            eachGroup={eachGroup}
            isAdminOfRest={isAdminOfRest}
            isHostess={isHostess}
            key={eachGroup.id}
            selectedTag={selectedTag}
          />
        ))}
      </div>
      {isOpenCreateModal && (
        <CreateGroupTagsModal
          isAdminOfRest={isAdminOfRest}
          isOpen={isOpenCreateModal}
          selectedTag={selectedTag}
          title={intl.formatMessage({ id: ETranslations.GROUP_CREATING })}
          onClose={onTogleOpenCreateModal}
        />
      )}
    </div>
  );
}
