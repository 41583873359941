import { Sentry } from "common/sentry";
import { config } from "config";
import { useTranslates } from "features/api/dictionaries-api";
import { useServiceWorker } from "hooks/useServiceWorker";
import { useUserLocale } from "hooks/useUserLocale";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import {
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "services/auth.service";
import { Notification } from "services/notification";
import { store } from "storage";
import "styles/normalize.scss";
import { ETranslations } from "types/translates";

import "../../index.scss";
import "../../legacy.scss";

export const rootLoader = ({ request }: LoaderFunctionArgs) => {
  const pathname = new URL(request.url).pathname;
  const isRoot = pathname === "/";
  const isLogin = pathname === "/login";
  const authenticated = AuthService.isAuthenticated();
  return authenticated
    ? isRoot || isLogin
      ? redirect("/dashboard")
      : null
    : isLogin
      ? null
      : redirect("/login");
};

const Root = () => {
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(
    AuthService.isAuthenticated(),
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    !authenticated &&
      pathname !== "/login" &&
      navigate("/login", { state: { hostessFrom: pathname } });
  }, [authenticated]);

  useEffect(() => {
    global?.channels?.auth && (global.channels.auth.cb = setAuthenticated);
  }, []);

  const { reloadPage, showReload, waitingWorker } = useServiceWorker();
  const { userLocale } = useUserLocale();
  const { translates } = useTranslates(userLocale);

  useEffect(() => {
    if (showReload && waitingWorker && !_.isEmpty(translates)) {
      Notification.warning({
        options: {
          autoClose: false,
          closeOnClick: false,
          draggable: true,
        },
        title: ETranslations.APP_VERSION_UPDATED,
        message: (
          <div>
            <button className="primary" type="button" onClick={reloadPage}>
              {translates[ETranslations.BASE_REFRESH]}
            </button>
          </div>
        ),
      });
    }
  }, [waitingWorker, showReload, reloadPage, translates]);

  return (
    <>
      <Helmet>
        <title>{config.brand_title}</title>
      </Helmet>
      <Outlet />
      <ToastContainer
        autoClose={3e3}
        position="bottom-right"
        closeOnClick
        draggable
        newestOnTop
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  );
};

export const RootProvider = () => (
  <Provider store={store}>
    <Sentry.ErrorBoundary>
      <Root />
    </Sentry.ErrorBoundary>
  </Provider>
);
