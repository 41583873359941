import { skipToken } from "@reduxjs/toolkit/dist/query";
import cn from "classnames";
import { BookingCard } from "components/BookingCard";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import { useGetOrderQuery } from "features/api/booking-order-api";
import type { OrderSlot } from "models/booking.model";
import React from "react";
import { Card, Spinner } from "ui-kit";

import { HideWhen } from "../../components/HideWhen";
import { HallSchema } from "../../components/hall-scheme/redux/HallSchemaV2/hall-schema";
import BookingFormReduxProxy from "../../components/registration/forms/BookingFormReduxProxy";
import { CreateFromOrder } from "../../components/registration/forms/create-from-order";
import { SelectGuestFromList } from "../../features/GuestsList/components/select-guest-from-list";
import { HallMode } from "../../features/HallSchema";
import { ETranslations } from "../../types/translates";
import styles from "../Dashboard/Dashboard.module.scss";
import { BookingOrdersList } from "./BookingOrdersList";
import useBookingOrders from "./hooks/useBookingOrders";
import useEditOrder from "./hooks/useEditOrder";

export const EditOrder = ({
  orderSelected,
  orderId,
}:
  | {
      orderSelected: unknown;
      orderId?: undefined;
    }
  | {
      orderSelected?: unknown;
      orderId: number | `${number}`;
    }) => {
  const { update } = useEditOrder();
  const { data, isLoading } = useGetOrderQuery(orderId ? orderId : skipToken);

  return orderSelected || data ? (
    <div className={styles.edit}>
      <div className={cn(styles.editCard)}>
        <BookingCard
          slot={orderSelected as OrderSlot}
          className={styles.bookingCard}
          compact
        />
      </div>
      <BookingFormReduxProxy component={CreateFromOrder} submitFn={update} />
    </div>
  ) : isLoading ? (
    <Spinner />
  ) : null;
};

export const BookingOrders: React.FC<any> = () => {
  const {
    intl,
    isTablet,
    hasOrder,
    onSelectOrder,
    handleClose,
    mode,
    orderSelected,
    getIntlEntityViewingAction,
    closeGuests,
    handleSetClient,
    closeHall,
  } = useBookingOrders();

  return (
    <>
      <HideWhen condition={isTablet && hasOrder}>
        <React.Suspense fallback={<Spinner />}>
          <BookingOrdersList onSelectOrder={onSelectOrder} />
        </React.Suspense>
      </HideWhen>
      <CloseBookingContext.Provider value={handleClose}>
        <HideWhen
          condition={
            (isTablet && !hasOrder) ||
            mode.includes("HALL") ||
            [
              HallMode.BOOKING_GUEST,
              HallMode.TABLE_BOOKINGS_EDIT_GUEST,
            ].includes(mode)
          }
          noUnmount
        >
          <Card onClose={handleClose}>
            <Card.Header title="Подтверждение" />
            <Card.Content noPadding>
              {hasOrder ? (
                <EditOrder orderSelected={orderSelected} />
              ) : (
                <div className={styles.emptyContainer}>
                  <div className={styles.icon} />
                  <p>
                    {getIntlEntityViewingAction(
                      ETranslations.OF_REQUEST,
                      ETranslations.PLURAL_REQUESTS_THEY,
                    )}
                  </p>
                </div>
              )}
            </Card.Content>
          </Card>
        </HideWhen>
        <HideWhen
          condition={
            ![
              HallMode.BOOKING_GUEST,
              HallMode.TABLE_BOOKINGS_EDIT_GUEST,
            ].includes(mode)
          }
        >
          <SelectGuestFromList
            onClose={closeGuests}
            onSelectGuest={handleSetClient}
          />
        </HideWhen>
      </CloseBookingContext.Provider>
      <HideWhen condition={!mode.includes("HALL")}>
        <Card onClose={closeHall}>
          <Card.Header
            title={intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
          />
          <Card.Content>
            <HallSchema />
          </Card.Content>
        </Card>
      </HideWhen>
    </>
  );
};
