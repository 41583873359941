import _ from "lodash";
import { config } from "../config";
import { ELocales, type Response } from "../types/commons";

interface AuthData {
  login: string;
  password: string;
  locale?: ELocales;
}

export type Role =
  | "ADMINISTRATOR"
  | "RESTAURANT"
  | "CALL_CENTER"
  | "ADMINISTRATOR_RESTAURANT"
  | "HOSTESS";

export type UserApp = {
  user_name: string;
  user_login: string;
  serial_id: number | null;
  user_id: number;
};

type AuthResponse = {
  token: string;
  refresh_token: string;
  user: UserApp;
};

type AuthRefresh = {
  access_token: string;
  refresh_token: string;
  expire_in: number;
  //
  token_type: string;
  session_id: string;
};

type TokensStorage = {
  access: string;
  refresh: string;
  //-
  accessExp: number;
  refreshExp: number;
};

export type AuthErrorCause = {
  code: number | undefined;
  message: string | undefined;
};

const STORAGE_TOKENS_KEY = "auth-tokens";

export class AuthService {
  private static parseJWT(token: string) {
    const parsed = JSON.parse(atob(token.split(".")[1]));
    return parsed;
  }

  static getTokens(): TokensStorage | null {
    const data = localStorage.getItem(STORAGE_TOKENS_KEY);
    if (!data) return null;
    try {
      return JSON.parse(data) as TokensStorage;
    } catch {
      this.deleteTokens();
      return null;
    }
  }

  static setTokens(access: string, refresh: string): void {
    const tokens: TokensStorage = {
      access,
      refresh,
      //-
      accessExp: this.parseJWT(access).exp * 1000,
      refreshExp: this.parseJWT(refresh).exp * 1000,
    };

    localStorage.setItem(STORAGE_TOKENS_KEY, JSON.stringify(tokens));
  }

  private static deleteTokens(): void {
    localStorage.removeItem(STORAGE_TOKENS_KEY);
  }

  static isAuthenticated(): boolean {
    const tokens = this.getTokens();

    if (!tokens) return false;

    const now = Date.now();

    return now <= tokens.accessExp || now <= tokens.refreshExp;
  }

  static needRefresh() {
    const tokens = this.getTokens();

    if (!tokens) return false;

    const now = Date.now();

    return now >= tokens.accessExp && now <= tokens.refreshExp;
  }


  static getUserRole(): Role {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user") ?? "").role ?? "HOSTESS";
    } else {
      // Not broken previous auth logic
      return (localStorage.getItem("role") as Role) ?? "HOSTESS";
    }
  }

  static async login(data: AuthData) {
    const resp: Response<AuthResponse> = await fetch(`api/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...data, tenant: config.tenant }),
    }).then((r) => r.json());

    if (!resp.data)
      throw new Error("Unable to login", {
        cause: {
          code: resp.errorCode,
          message: resp.errorMessage,
        } satisfies AuthErrorCause,
      });

    const { token, refresh_token, user } = resp.data;
    AuthService.setTokens(token, refresh_token);
    return user;
  }

  private static refreshPromise: Promise<unknown> | undefined;

  static async refresh() {
    if (this.refreshPromise) return this.refreshPromise;

    const promise = (async () => {
      const tokens = this.getTokens();

      if (!tokens || !this.isAuthenticated()) {
        this.logout();
        throw new Error("Error! AuthService: no token to refresh");
      }

      const res = await fetch(`/api/auth/refresh`, {
        method: `POST`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh_token: tokens.refresh }),
      });

      if ([401, 403].includes(res.status)) {
        this.logout();
        throw new Error("Error! AuthService: refresh token in not valid");
      }

      if (res.status !== 200) {
        throw new Error("Error! AuthService: error while refreshing tokens");
      }

      const { access_token, refresh_token } = (await res.json()) as AuthRefresh;
      AuthService.setTokens(access_token, refresh_token);
    })();

    promise.finally(() => {
      if (this.refreshPromise === promise) this.refreshPromise = undefined;
    });

    this.refreshPromise = promise;
    return promise;
  }

  static async fetchWithAuthentication(
    req: Request,
    isRetry = false,
  ): Promise<globalThis.Response> {
    if (isRetry || AuthService.needRefresh()) {
      await AuthService.refresh();
    }

    const tokens = this.getTokens();
    if (!tokens || !this.isAuthenticated()) {
      this.logout();
      throw new Error("Unauthorized request: no tokens or tokens expired");
    }

    req.headers.set("Authorization", tokens.access);

    const resp = await global.fetch(req);

    if (resp.status === 401) {
      if (!isRetry) {
        return this.fetchWithAuthentication(req.clone(), true);
      }

      this.logout();
      return resp;
    }

    return resp;
  }

  static logout = async () => {
    AuthService.deleteTokens();
    // TODO: удалить эту строчку где-нибудь в 2025 :)
    localStorage.removeItem("auth-token-wrf");
    try {
      _.get(global, "channels.auth.cb")(false);
    } catch (e) {
      console.warn("Logout error");
    }
  };
}
