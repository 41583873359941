import { BookingHistory } from "components/BookingHistory";
import { HideWhen } from "components/HideWhen";
import { EditManagerialBooking } from "components/ManagerialTables/form/EditManagerialBooking";
import { DashboardBookings } from "components/booking/DashboardBookings";
import { CreateBooking } from "components/hall-scheme/redux/Booking/CreateBooking";
import { HallSchema } from "components/hall-scheme/redux/HallSchemaV2/hall-schema";
import { EditBooking } from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import { BookingChat } from "containers/Dashboard/SlotDetails/BookingChat/BookingChat";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import { SelectGuestFromList } from "features/GuestsList/components/select-guest-from-list";
import { HallMode } from "features/HallSchema";
import type { ManagerSlot } from "models/booking.model";
import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Card, Spinner } from "ui-kit";
import { NavLinkTabs } from "ui-kit/Card/NavLinkTabs";

import useDashBoards, { Tab } from "./useDashBoards";

export function Dashboard() {
  const {
    hasBooking,
    isManagerialTableBooking,
    booking,
    emptyMessage,
    timestamp,
    isTablet,
    isCreatingBooking,
    mode,
    resetBooking,
    closeHall,
    intl,
    closeGuests,
    handleSetClient,
    isGuestMode,
    tab,
    getIntlHistoryOf,
    setTab,
    close,
    isRootDashboard,
  } = useDashBoards();

  /* const EditSection = useMemo(() => {
    if (hasBooking && !isManagerialTableBooking && booking?.slot_id) {
      return <EditBooking bookingId={booking.slot_id} />;
    } else if (hasBooking && isManagerialTableBooking) {
      return (
        <EditManagerialBooking booking={booking as unknown as ManagerSlot} />
      );
    } else {
      return emptyMessage;
    }
  }, [hasBooking, isManagerialTableBooking, booking, timestamp]);
 */
  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen
          condition={
            (isTablet && (hasBooking || !isRootDashboard)) ||
            (isTablet && isCreatingBooking)
          }
          noUnmount
        >
          <DashboardBookings />
        </HideWhen>
      </React.Suspense>
      <CloseBookingContext.Provider value={hasBooking ? close : null}>
        <HideWhen condition={mode.includes("HALL") || isGuestMode} noUnmount>
          <Outlet />
        </HideWhen>
      </CloseBookingContext.Provider>
    </>
  );
}

/*                 <Card.HeaderTabs activeTab={tab} onChange={setTab}>
                  <Card.HeaderTabs.Item
                    tabName={Tab.Edit}
                    title={intl.formatMessage({
                      id: ETranslations.BOOKING_MANAGEMENT,
                    })}
                  />
                  <Card.HeaderTabs.Item
                    disabled={!hasBooking}
                    tabName={Tab.History}
                    title={getIntlHistoryOf(ETranslations.PLURAL_CHANGES)}
                  />
                  {booking && (
                    <Card.HeaderTabs.Item
                      disabled={!booking.client?.client_id}
                      tabName={Tab.Chat}
                      title={intl.formatMessage({ id: ETranslations.CHAT })}
                    />
                  )}
                </Card.HeaderTabs>
                <HideWhen condition={tab !== Tab.Edit} noUnmount>
                  <Card.Content noPadding>{EditSection}</Card.Content>
                </HideWhen>
                <HideWhen condition={tab !== Tab.History}>
                  {booking ? (
                    <BookingHistory booking={booking} isExpanded />
                  ) : (
                    emptyMessage
                  )}
                </HideWhen>
                {booking && booking.client?.client_id && (
                  <HideWhen condition={tab !== Tab.Chat}>
                    <Card.Content noPadding>
                      <BookingChat booking={booking} />
                    </Card.Content>
                  </HideWhen>
                )} */
