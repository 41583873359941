import { getTimeOption } from "components/registration/forms/utils";
import dayjs from "dayjs";
import { useFromProxyActions } from "features/BookingFormProxy";
import { useHallSchemaActions } from "features/HallSchema";
import { invalidateHallSlots } from "features/api/hallschema-api";
import {
  useEditManagerialTableMutation,
  useGetManagerialTableQuery,
} from "features/api/managerialTables";
import type { ManagerSlot } from "models/booking.model";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useEditManagerialBooking = (booking: ManagerSlot) => {
  const { reset: closeEdit, setEditMode } = useFromProxyActions();
  const { data, isLoading } = useGetManagerialTableQuery(booking.slot_id);
  const [value, setValue] = useState({ start_date: undefined });
  const [managerialError, setManagerialError] = useState<string>("");
  const [editManagerlBooking] = useEditManagerialTableMutation();
  const dispatch = useDispatch();
  const { setActiveTables } = useHallSchemaActions();

  const onSubmit = useCallback(
    async (values) => {
      const { tables, time, end_date, bookingId, visit_time, duration } =
        values;
      const payload = {
        date: end_date,
        time: `${dayjs(time.value).format("HH:mm:ss")}`,
        table_ids: tables,
        duration,
        visit_time,
      };
      editManagerlBooking({ id: bookingId, payload }).then((res) => {
        // @ts-ignore
        if (res?.error) {
          // @ts-ignore
          if (res.error.data.errorCode === 10100) {
            //@ts-ignore
            setManagerialError(res.error.data.errorMessage);
            setActiveTables({
              activeTables: data?.slot_place.map(
                (place) => place.table_id,
              ) as number[],
            });
          }
        } else {
          dispatch(invalidateHallSlots());
          setEditMode(false);
        }
      });
    },
    [data],
  );

  const closeErrorModal = () => {
    setManagerialError("");
  };

  useEffect(() => {
    setValue({
      //@ts-ignore
      start_date: data?.date,
      end_date: data?.date,
      time: getTimeOption(data?.time),
      duration: data?.visit_duration,
      tables: data?.slot_place.map((place) => place.table_id),
      placeId: data?.slot_place[0]?.place_id,
      date: new Date(`${data?.date} ${data?.time}`),
      persons: 1,
      bookingId: data?.slot_id,
    });
  }, [data, isLoading, booking]);

  return {
    isLoading,
    closeEdit,
    data,
    value,
    onSubmit,
    managerialError,
    closeErrorModal,
  };
};

export default useEditManagerialBooking;
