import dayjs from "dayjs";
import { useTimelineActions } from "features/Timeline";
import { type TableInfo, useTablesInfo } from "hooks/useTablesOptions";
import { useMemo } from "react";
import { useField } from "react-final-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { preventDefault, stopPropagation } from "utils";

import {
  HallMode,
  useHallSchemaActions,
} from "../../../../features/HallSchema";
import { hallModeSelector } from "../../../../features/HallSchema/selectors";
import { useIsTablet } from "../../../../hooks/useIsTablet";
import {
  isBookingView,
  isRegView,
} from "../../../hall-scheme/redux/HallSchemaV2/hall-helpersFn";

const useChoiceAutoTableInputRedux = (
  disabled: boolean,
  isCreateBooking: boolean,
  isShowTableScheme: boolean,
) => {
  const intl = useIntl();
  const tablesInfo = useTablesInfo();
  const { switchMode } = useHallSchemaActions();
  const { setTimestamp } = useTimelineActions();
  const isTablet = useIsTablet();
  const {
    input: { value: personValue },
  } = useField("persons");
  const {
    input: { onChange: changeTables, value },
  } = useField("tables");

  const {
    input: { value: dateValue },
  } = useField("date");

  const {
    input: { value: timeValue },
  } = useField("time");

  const hasTableValue = useMemo(
    () => (Array.isArray(value) ? !!value.length : !!value),
    [value],
  );

  const isDisabled = useMemo(
    () => disabled || !tablesInfo?.length || !personValue || hasTableValue,
    [personValue, tablesInfo, hasTableValue, disabled],
  );

  const handleOnChange = () => {
    if (isDisabled) return;
    const NAME = tablesInfo.filter(
      (it) => (it?.maxCapacity || NaN) >= personValue,
    );
    const autoTable = tablesInfo.reduce<{
      biggestTable: TableInfo | undefined;
      currentTable: TableInfo | undefined;
    }>(
      (result, table) => {
        // Если в списке столов находим стол, максимальная капасити которого больше, чем у нас записано в результате,
        table.maxCapacity > (result.biggestTable?.maxCapacity || 0) &&
          // то записываем этот стол в результат
          (result.biggestTable = table);

        // Если находим стол который удовлетворяет всем условиям:
        // Его максимальная капасити позволяет посадить нужное количество персон
        table.maxCapacity >= personValue &&
          // минимальная капасити позволяет посадить нужное количество персон
          table.minCapacity <= personValue &&
          // и в результате пока не записан стол
          (!result.currentTable ||
            //или записан стол менее идеальным пределом капасити
            table.maxCapacity < result.currentTable.maxCapacity) &&
          // то записываем этот стол в результат
          (result.currentTable = table);
        // возвращаем результат
        return result;
      },
      {
        biggestTable: undefined,
        currentTable: undefined,
      },
    );
    // Если хоть что-то нашли из столов, то выполняем функцию автовыбора стола
    (autoTable.currentTable || autoTable.biggestTable) &&
      // Если нашли подходящий стол, то выбираем его, а если нет, то просто самый вместительный
      changeTables([
        autoTable.currentTable?.tableId || autoTable.biggestTable?.tableId,
      ]);
  };

  const hallMode = useSelector(hallModeSelector);

  const showSchema = (event: any) => {
    const date = dayjs(dateValue);
    const time = dayjs(timeValue.label, "HH:mm");
    const hour = time.hour();
    const minute = time.minute();
    const timestamp = date
      .clone()
      .set("hour", hour)
      .set("minute", minute)
      .valueOf();
    setTimestamp(timestamp);

    preventDefault(event);
    stopPropagation(event);
    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_HALL);
      return;
    }
    if (isBookingView(hallMode)) {
      switchMode(HallMode.BOOKING_HALL);
      return;
    }
    switchMode(HallMode.EDIT_HALL);
  };

  const isShowSchema = useMemo(() => {
    if (!isTablet && isCreateBooking) {
      return false;
    }

    if (isTablet) {
      return false;
    }

    return true;
  }, [isCreateBooking, isTablet, isShowTableScheme]);
  return {
    showSchema,
    isShowSchema,
    handleOnChange,
    intl,
    isDisabled,
  };
};

export default useChoiceAutoTableInputRedux;
