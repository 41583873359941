import { TimeSelect } from "components/TimeSelect";
import dayjs from "dayjs";
import React from "react";

import useChoiceTimeInputNew from "./hooks/useChoiceTimeInputNew";

export const ChoiceTimeInputNew = ({
  disabled,
  allowValueFromOutsideRange = false,
  isEditMode,
  isFromManagement = false,
}: {
  disabled: boolean;
  allowValueFromOutsideRange?: boolean;
  isEditMode: boolean;
  isFromManagement?: boolean;
}) => {
  const { allShifts, handleChange, startAndEnd, dateValue, value } =
    useChoiceTimeInputNew(
      allowValueFromOutsideRange,
      isFromManagement,
      disabled,
    );

  return (
    <TimeSelect
      allShifts={allShifts}
      currentTime={dayjs()}
      dateValue={dateValue}
      disabled={disabled}
      inputValue={value}
      isEditMode={isEditMode}
      isFromManagement={isFromManagement}
      startAndEnd={startAndEnd}
      onChange={handleChange}
    />
  );
};
