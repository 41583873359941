import { GuestBadge } from "components/GuestInfo";
import moment from "moment";
import { FunctionComponent, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { LinkButton } from "ui-kit";

import { useAllStatuses } from "../../features/api/dictionaries-api";
import { useFetchClientQuery } from "../../features/api/guest-api";
import { BookingClientHistoryList } from "./BookingClientHistoryList";
import { OrderClientHistoryList } from "./OrderClientHistoryList";
import { SearchBlock } from "./SearchBlock";

interface Props {
  clientId: number | `${number}`;
}

export interface IPayloadClientHistory {
  from: Date | string;
  to: Date | string;
  statuses?: string[];
  count: number;
  offset: number;
}

export type TModeHistory = "order" | "booking";

const initialPayload = {
  from: moment().format("YYYY-MM-DD"),
  to: moment().format("YYYY-MM-DD"),
  statuses: [],
  count: 10,
  offset: 0,
};

export const BookingClientHistory: FunctionComponent<Props> = ({
  clientId,
}) => {
  const { statuses: statusList } = useAllStatuses();
  const { formatMessage } = useIntl();
  const statuses = statusList.filter((status) => !status.is_extra);
  const systemNameArray = statuses.map((status) => status.system_name);
  const [payload, setPayload] = useState<IPayloadClientHistory>({
    ...initialPayload,
    statuses: systemNameArray,
  });
  const [mode, setMode] = useState<TModeHistory>("booking");
  const { data: client } = useFetchClientQuery(clientId);

  if (!clientId) return null;

  const paginationHandler = useCallback((offset: number) => {
    setPayload((oldState) => ({
      ...oldState,
      offset: offset * oldState.count - oldState.count,
    }));
  }, []);

  return (
    <section>
      <GuestBadge guest={client} isHideEditPhoto>
        <LinkButton variant="primary" to="/create-booking">
          {formatMessage({ id: ETranslations.CREATE_BOOKING })}
        </LinkButton>
      </GuestBadge>
      <SearchBlock
        mode={mode}
        payload={payload}
        setMode={setMode}
        setPayload={setPayload}
        statuses={statuses}
      />
      {mode === "booking" ? (
        <BookingClientHistoryList
          clientId={clientId}
          mode={mode}
          paginationHandler={paginationHandler}
          payload={payload}
        />
      ) : (
        <OrderClientHistoryList
          clientId={clientId}
          mode={mode}
          paginationHandler={paginationHandler}
          payload={payload}
        />
      )}
    </section>
  );
};
