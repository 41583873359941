import { useRestsOptions } from "features/AppContex/selectors";
import {
  DialogFilterKind,
  DialogStatus,
  DialogsFilter,
} from "features/Dialogs/SDK";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Outlet, useOutlet } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { ETranslations } from "types/translates";
import { useIsTablet } from "ui-kit/hooks";

import { DialogList } from "./DialogList/DialogList";
import { DialogFilters } from "./DialogsFilters/DialogsFilters";
import { DIALOG_FILTERS_LOCALSTORAGE_KEY } from "./constans";
import {
  Filters,
  type Option,
  getFiltersCounter,
  getValidFiltersValues,
} from "./utils";

const ARRAY_EMPTY: Array<any> = [];

export const Dialogs = () => {
  const { formatMessage } = useIntl();
  const restsOptions = useRestsOptions();
  const outlet = useOutlet();
  const isTablet = useIsTablet();

  const allowedRestaurantIds = useMemo(
    () => restsOptions.map((option) => String(option.value)),
    [restsOptions],
  );

  const initialFilters = {
    restaurant: [],
    activity: [
      {
        value: DialogStatus.ALL,
        label: formatMessage({ id: ETranslations.BASE_ALL }),
      },
    ],
  } as const satisfies Filters;

  const chatActivityOptions = [
    {
      value: DialogStatus.ACTIVE,
      label: formatMessage({ id: ETranslations.BASE_ACTIVE }),
    },
    {
      value: DialogStatus.CLOSED,
      label: formatMessage({ id: ETranslations.CLOSED_ALT }),
    },
    {
      value: DialogStatus.ALL,
      label: formatMessage({ id: ETranslations.BASE_ALL }),
    },
  ];
  const [filters, setFilters] = useLocalStorage<Filters>(
    DIALOG_FILTERS_LOCALSTORAGE_KEY,
    initialFilters,
  );

  useEffect(() => {
    setFilters(
      getValidFiltersValues(
        filters,
        {
          restaurant: restsOptions,
          activity: chatActivityOptions,
        },
        initialFilters,
      ),
    );
  }, []);

  const changeFilters = useMemo(
    () =>
      <K extends keyof Filters>(
        filterKey: K,
        currentFilters: Filters,
        selectedOptions: Option[] | Option,
      ) => {
        setFilters({
          ...currentFilters,
          [filterKey]: Array.isArray(selectedOptions)
            ? selectedOptions
            : [selectedOptions],
        });
      },
    [],
  );

  const filtersCounter = filters && getFiltersCounter(filters, initialFilters);

  const selectedRestaurantIds = useMemo(() => {
    return filters?.restaurant.length
      ? filters.restaurant.map((option) => String(option.value))
      : allowedRestaurantIds;
  }, [filters, allowedRestaurantIds]);

  const dialogsPayload: DialogsFilter = useMemo(() => {
    return {
      partners: selectedRestaurantIds,
      customerTypes: ARRAY_EMPTY,
      projects: ARRAY_EMPTY,
      taskTypes: ARRAY_EMPTY,
      kind: DialogFilterKind.ALL,
      status: filters?.activity[0]?.value || DialogStatus.ALL,
    };
  }, [selectedRestaurantIds, filters?.activity[0]?.value]);

  // Если планшет и есть активный outlet, то рендерим его, если не планшет или нет активного outlet то рендерим DialogList
  return (
    (isTablet && outlet) || (
      <>
        <DialogList
          dialogsPayload={dialogsPayload}
          filtersCounter={filtersCounter}
          formatMessage={formatMessage}
        >
          <DialogFilters
            chatActivityOptions={chatActivityOptions}
            labels={{
              restaurant: formatMessage({
                id: ETranslations.PLURAL_RESTAURANT,
              }),
              activity: formatMessage({
                id: ETranslations.CHAT_ACTIVITY,
              }),
              resetButton: formatMessage({
                id: ETranslations.RESET_FILTERS,
              }),
            }}
            restsOptions={restsOptions}
            selectedFilters={filters || initialFilters}
            changeFilters={changeFilters}
            resetFilters={() =>
              JSON.stringify(filters) !== JSON.stringify(initialFilters) &&
              setFilters(initialFilters)
            }
          />
        </DialogList>
        {outlet}
      </>
    )
  );
};
