import { TagId } from "models/tags.model";
import { z } from "zod";

export const ClientId = z.number().brand("ClientId");
export type ClientId = z.infer<typeof ClientId>;
const ContactId = z.number().brand("ContactId");
export type ContactId = z.infer<typeof ContactId>;
export const Client = z
  .discriminatedUnion("contact_type", [
    z.object({
      contact_type: z.literal("CLIENT"),
      client_id: ClientId,
      contact_id: z.null().optional(),
    }),
    z.object({
      contact_type: z.literal("CONTACT"),
      contact_id: ContactId,
      client_id: z.null().optional(),
    }),
  ])
  .and(
    z.object({
      tags: TagId.array(),
      gradeName: z.string().optional(),
      vip: z.boolean().nullable(),
      middle_name: z.string().nullable().optional(),
      gradeColor: z.string().optional(),
      phone: z.string().optional(),
      email: z.string().nullable().optional(),
      surname: z.string().nullable().optional(),
      name: z.string().nullable().optional(),
      display_name: z.string(),
    }),
  );
export type Client = z.infer<typeof Client>;
