import { CreateManagerialForm } from "components/ManagerialTables/form/CreateManagerialForm";
import { useMoveBookingActions } from "features/MoveBooking";
import React, { CSSProperties, createContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { type Location, useLocation } from "react-router-dom";
import { useUnmount } from "react-use";

import {
  HorizontalWindowsLayout,
  HorizontalWindowsLayoutColumn,
  HorizontalWindowsLayoutItem,
} from "../../../common/components/HorizontalWindowLayout/horizontal-windows-layout";
import { usePageId } from "../../../contexts/LayoutContext";
import { useFromProxyActions } from "../../../features/BookingFormProxy";
import { SelectGuestFromList } from "../../../features/GuestsList/components/select-guest-from-list";
import { HallMode, useHallSchemaActions } from "../../../features/HallSchema";
import { hallModeSelector } from "../../../features/HallSchema/selectors";
import { useIsLandscape } from "../../../hooks/useIsDeviceLandscape";
import { useIsTabletBooking } from "../../../hooks/useIsTablet";
import { CreateBooking } from "./Booking/CreateBooking";
import { RegisterVisit } from "./Booking/RegisterVisit";
import styles from "./HallContainer.module.scss";
import {
  isBookingView,
  isGuestView,
  isHallView,
  isManagerialTableView,
  isRegView,
  isTableBookingView,
} from "./HallSchemaV2/hall-helpersFn";
import { HallSchema } from "./HallSchemaV2/hall-schema";
import { RightSideComponent } from "./RightSideComponent";
import { TableBookingList } from "./TableBookingList/table-booking-list";
import { TabletHallPopup } from "./TabletHallPopup";
import type { Client } from "types/client";

export const IsMountedInHall = createContext(false);

const DesktopVersion = ({
  initialFormValues,
}: {
  initialFormValues: Record<string, any> | undefined;
}) => {
  const { reset, resetDesktop, switchMode } = useHallSchemaActions();
  const { setClient } = useFromProxyActions();
  const { clearSelection } = useMoveBookingActions();
  useUnmount(resetDesktop);
  useUnmount(clearSelection);
  const hallMode = useSelector(hallModeSelector);
  const actionView =
    hallMode !== HallMode.TABLES &&
    hallMode !== HallMode.MOVE_BOOKINGS_CAPTURED;

  const desktopGridStyles: CSSProperties = actionView
    ? {
        gridGap: "var(--header-spacing)",
        gridTemplateColumns: "max-content 1fr",
      }
    : {
        display: "block",
      };
  const guestCloseHandler = () => {
    if (hallMode === HallMode.REGISTRATION_GUESTS)
      switchMode(HallMode.REGISTRATION_HALL);
    if (hallMode === HallMode.BOOKING_GUEST) switchMode(HallMode.BOOKING_HALL);
  };
  const selectGuestHandler = (guest: Client) => {
    setClient({ client: guest });
    if (isRegView(hallMode)) switchMode(HallMode.REGISTRATION_HALL);
    if (isBookingView(hallMode)) switchMode(HallMode.BOOKING_HALL);
    if (isTableBookingView(hallMode)) switchMode(HallMode.TABLE_BOOKINGS_EDIT);
  };

  // todo: сброс после сабмита формы не отрабатывает
  return (
    <>
      {!actionView ? (
        <HorizontalWindowsLayout name="desktop" styles={desktopGridStyles}>
          <HorizontalWindowsLayoutColumn name="component">
            <HallSchema />
          </HorizontalWindowsLayoutColumn>
        </HorizontalWindowsLayout>
      ) : (
        <HorizontalWindowsLayout name="desktop" styles={desktopGridStyles}>
          <HorizontalWindowsLayoutColumn
            name="forms"
            style={{ width: "570px" }}
          >
            {isManagerialTableView(hallMode) && (
              <HorizontalWindowsLayoutItem
                name="managerial-booking"
                render={() => <CreateManagerialForm reset={reset} />}
                pinned
              />
            )}
            {isRegView(hallMode) && (
              <HorizontalWindowsLayoutItem
                name="registration"
                render={() => <RegisterVisit reset={reset} />}
                pinned
              />
            )}

            {isBookingView(hallMode) && (
              <HorizontalWindowsLayoutItem
                name="booking"
                render={() => (
                  <CreateBooking
                    reset={reset}
                    initialFormValues={initialFormValues}
                  />
                )}
                pinned
              />
            )}
            {isTableBookingView(hallMode) && (
              <HorizontalWindowsLayoutItem
                name="table-bookings"
                render={() => <TableBookingList />}
                active
              />
            )}
          </HorizontalWindowsLayoutColumn>
          <HorizontalWindowsLayoutColumn name="component">
            {isHallView(hallMode) && (
              <HorizontalWindowsLayoutItem
                name="hall-schema"
                render={() => <HallSchema />}
                active
                initialVisible
              />
            )}
            {isGuestView(hallMode) && (
              <HorizontalWindowsLayoutItem
                name="guest-view"
                render={() => (
                  <SelectGuestFromList
                    onClose={guestCloseHandler}
                    onSelectGuest={(guest) => selectGuestHandler(guest)}
                  />
                )}
                active
              />
            )}
          </HorizontalWindowsLayoutColumn>
        </HorizontalWindowsLayout>
      )}
    </>
  );
};

const TableVersion = ({
  initialFormValues,
}: {
  initialFormValues: Record<string, any> | undefined;
}) => {
  const { reset, resetTablet } = useHallSchemaActions();
  useUnmount(resetTablet);
  const hallMode = useSelector(hallModeSelector);
  const isLandscapeDevice = useIsLandscape();
  const formByMode = () => {
    switch (hallMode) {
      case HallMode.MANAGERIAL_TABLET:
      case HallMode.MANAGERIAL_HALL:
      case HallMode.MANAGERIAL_BOOKING:
        return <CreateManagerialForm reset={reset} />;
      case HallMode.TABLES:
      case HallMode.MOVE_BOOKINGS_CAPTURED:
        return <HallSchema />;
      case HallMode.REGISTRATION_TABLET:
      case HallMode.REGISTRATION_GUESTS:
      case HallMode.REGISTRATION_HALL:
        return <RegisterVisit reset={reset} />;
      case HallMode.BOOKING_TABLET:
      case HallMode.BOOKING_GUEST:
      case HallMode.BOOKING_HALL:
        return (
          <CreateBooking reset={reset} initialFormValues={initialFormValues} />
        );
      case HallMode.TABLE_BOOKINGS_LIST:
      case HallMode.TABLE_BOOKINGS_EDIT:
      case HallMode.TABLE_BOOKINGS_EDIT_GUEST:
      case HallMode.EDIT_HALL:
        return <TableBookingList />;
      default:
        return <HallSchema />;
    }
  };

  if (isLandscapeDevice)
    return (
      <>
        <HorizontalWindowsLayout>
          <HorizontalWindowsLayoutColumn name="tablet">
            <HorizontalWindowsLayoutItem
              name="form"
              render={() => formByMode()}
              initialVisible
            />
            <HorizontalWindowsLayoutItem
              name="hall"
              render={() => <TabletHallPopup hallMode={hallMode} />}
              pinned
            />
          </HorizontalWindowsLayoutColumn>
        </HorizontalWindowsLayout>
      </>
    );

  return (
    <div className={styles.tabletBookingContainer}>
      {formByMode()}
      <RightSideComponent hallMode={hallMode} />
    </div>
  );
};

export const HallContainer: React.FC<{ isCreateBookingOpened?: boolean }> = ({
  isCreateBookingOpened,
}) => {
  const { state } = useLocation() as Location<
    { isBookingFormData: true; formData: Record<string, any> } | undefined
  >;
  const isTablet = useIsTabletBooking();
  const { switchMode } = useHallSchemaActions();
  usePageId("hall-scheme");
  useLayoutEffect(() => {
    if (isCreateBookingOpened) {
      switchMode(
        isTablet
          ? HallMode.BOOKING_TABLET
          : state?.isBookingFormData
            ? HallMode.BOOKING_HALL
            : HallMode.BOOKING_GUEST,
      );
    }
  }, [isCreateBookingOpened]);

  return (
    <IsMountedInHall.Provider value>
      {isTablet ? (
        <TableVersion
          initialFormValues={
            state?.isBookingFormData ? state.formData : undefined
          }
        />
      ) : (
        <DesktopVersion
          initialFormValues={
            state?.isBookingFormData ? state.formData : undefined
          }
        />
      )}
    </IsMountedInHall.Provider>
  );
};
