import { Source, type SourceId, SourceServer } from "models/booking.model";
import { Response } from "models/common";

import { apiINT } from "./api-data";
import { RequestWrapper } from "./request-wrapper";

export class SourcesService {
  static async getAll(): Promise<Response<SourceServer[]>> {
    return RequestWrapper.fetch(`${apiINT}/v2/sources`).then((response) =>
      response.json(),
    );
  }

  static mockAll(): Source[] {
    return [
      { source_name: "Source_1", id: 1 as SourceId },
      { source_name: "Source_3", id: 3 as SourceId },
      { source_name: "Source_2", id: 2 as SourceId },
    ];
  }
}
