import cn from "classnames";
import { DetailsBookingCard } from "components/BookingCard/DashboardBookingCard";
import ManagerialModalError from "components/ManagerialTables/form/ManagerialModalError";
import { ConfirmOverbookingModal } from "components/modals/ConfirmOverbookingModal";
import BookingFormReduxProxy from "components/registration/forms/BookingFormReduxProxy";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import type { BookingSlot, SlotId } from "models/booking.model";
import React from "react";
import type { Booking } from "types/booking";
import { Card, Input, Spinner } from "ui-kit";

import { BookingCardDetailList } from "./BookingCardDetailList";
import { TableActions } from "./TableActions";
import styles from "./TableBookingList.module.scss";
import useEditBooking from "./hooks/useEditBooking";
import useTableBookingList from "./hooks/useTableBookingList";

interface EditBookingProps {
  bookingId: SlotId;
  hideCard?: boolean;
}

export const EditBooking = ({ bookingId, hideCard }: EditBookingProps) => {
  const {
    booking,
    update,
    overbookingData,
    clearData,
    updateWithOverbooking,
    hasManagerialError,
    closeModal,
    isUpdating,
  } = useEditBooking(bookingId);

  return (
    <div className={styles.edit}>
      {!hideCard && (
        <div className={cn(styles.sectionContent, styles.editCard)}>
          {booking ? <DetailsBookingCard booking={booking} /> : <Spinner />}
        </div>
      )}
      {booking && (
        <BookingFormReduxProxy
          booking={booking}
          isAnonym={booking?.client?.contact_type === "CONTACT"}
          isCreating={false}
          isUpdate={true}
          submitFn={update}
        />
      )}
      <ConfirmOverbookingModal
        disabled={isUpdating}
        isOpen={!!overbookingData}
        onConfirm={updateWithOverbooking}
        onDecline={clearData}
      />
      {hasManagerialError && (
        <ManagerialModalError
          isOpen={hasManagerialError}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export const TableBookingList: React.FC = () => {
  const {
    closeWindowHandler,
    handleClose,
    tableData,
    searchValue,
    setSearchValue,
    isEdit,
    intlTitles,
    isLoading,
    booking,
    bookingList,
    isRegisterDisabled,
  } = useTableBookingList();

  return (
    <CloseBookingContext.Provider value={closeWindowHandler}>
      <Card id="table-bookings" onClose={handleClose}>
        <Card.Header title={`Брони стола №${tableData?.number || ""}`}>
          <div className="right-block">
            <Input.Search
              value={searchValue}
              onSearch={(search) => setSearchValue({ search })}
            />
          </div>
        </Card.Header>
        {isEdit ? (
          <Card.Content>
            <Card.Header title={intlTitles.bookingEdit} />
            {isLoading && <Spinner />}
            <EditBooking bookingId={(booking as Booking).bookingId as SlotId} />
          </Card.Content>
        ) : (
          <Card.Content className={styles.listContainer} noPadding>
            <div className={styles.list}>
              {isLoading && <Spinner />}
              <BookingCardDetailList data={bookingList as BookingSlot[]} />
            </div>
            {tableData && (
              <TableActions
                isRegisterDisabled={isRegisterDisabled}
                table={tableData}
              />
            )}
          </Card.Content>
        )}
      </Card>
    </CloseBookingContext.Provider>
  );
};
