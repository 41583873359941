import { skipToken } from "@reduxjs/toolkit/dist/query";
import { PopupRef } from "components/Popup";
import dayjs from "dayjs";
import { useFromProxyActions } from "features/BookingFormProxy";
import type { FormBooking } from "features/BookingFormProxy/types";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { useMoveBookingActions } from "features/MoveBooking";
import { useTimelineActions } from "features/Timeline";
import { useChangeBookingStatus, useGetBookingStatusQuery, usePrintBookingInfo } from "features/api/bookings-api";
import { useCancelManagerialTableMutation } from "features/api/managerialTables";
import { useBooleanState } from "hooks/useBooleanState";
import { useRecoveryBooking } from "hooks/useRecoveryBooking";
import type { BookingSlot, ManagerSlot } from "models/booking.model";
import { useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getBookingStartTime, isBooking, isManagerialTable } from "utils";



import { useIntlUtils } from "../../../hooks/useIntlUtils";


const useBookingActions = (
  booking: BookingSlot | ManagerSlot,
  onEdit?: (booking: BookingSlot | ManagerSlot) => void,
) => {
  const { intl } = useIntlUtils();
  const { pathname } = useLocation();
  const { recovery } = useRecoveryBooking(booking.slot_id);
  const { reset: closeEdit } = useFromProxyActions();
  const { switchMode, setActiveTables } = useHallSchemaActions();
  const { setTimestamp, setTime, setVisistTime } = useTimelineActions();

  const { print, isDisabled } = usePrintBookingInfo(booking.slot_id);

  const [wasOpen, setWasOpen] = useBooleanState(false);
  const [cancel] = useCancelManagerialTableMutation();

  const popup = useRef<PopupRef>(null);

  const { data: status, isLoading } = useGetBookingStatusQuery(
    wasOpen ? booking.slot_id ?? skipToken : skipToken,
  );
  const {
    force,
    nextStatus: goNextStatus,
    terminalStatus,
    resetForce,
  } = useChangeBookingStatus(
    booking.slot_id,
    (isBooking(booking) && booking?.client?.client_id) || NaN,
  );
  const handleEdit = useCallback(() => {
    onEdit?.(booking as BookingSlot);
    popup.current?.close();
  }, [booking, onEdit]);

  const nextStatus = useMemo(() => status?.next || status?.terminal, [status]);

  const handleStatusChange = useCallback(async () => {
    try {
      popup.current?.close();
      // eslint-disable-next-line no-unused-expressions
      status?.next ? goNextStatus() : terminalStatus();
    } catch {
      //
    }
  }, [status]);

  const { selectMoveSource, selectSourceTableNumber } = useMoveBookingActions();
  const tableName = booking.slot_place.map((place) => place.table_name);
  const handleMoveClick = useCallback(() => {
    setTime(getBookingStartTime(booking.date, booking.time));
    booking.slot_id && selectMoveSource(booking.slot_id);
    selectSourceTableNumber(tableName);
  }, [booking]);

  const [isCancel, show, hide] = useBooleanState(false);

  const handlePrint = useCallback(() => {
    print();
    popup.current?.close();
  }, [print]);

  const createBooking = () => {
    switchMode(HallMode.MANAGERIAL_CREATE_BOOKING);
    const tables = booking.slot_place.map((each) => each.table_id);
    setActiveTables({ activeTables: tables });
    setTimestamp(dayjs(`${booking.date} ${booking.time}`).valueOf());
    closeEdit();

    if (isManagerialTable(booking)) {
      //@ts-ignore
      setVisistTime(booking.visitTime);
    }
  };

  const isManagerialTableAndTimePassed = useMemo(() => {
    if (
      isManagerialTable(booking) &&
      dayjs().valueOf() >=
        dayjs(`${booking.date}: ${booking.time}`)
          .add(booking?.visit_duration, "minute")
          .valueOf()
    ) {
      return true;
    } else {
      return false;
    }
  }, [booking]);

  const managerialFormBookingState: Partial<
    FormBooking & { initialVisitTime: number }
  > | undefined = booking.slot_type === "MANAGER" ? {
    tables: booking.slot_place.map((place) => place.table_id),
    seatType: "MANAGER_BOOK",
    date: new Date(booking.date),
    visit_time: booking.visit_duration,
    initialVisitTime: booking.visit_duration,
  } : undefined;
  

  return {
    isLoading,
    isCancel,
    handleEdit,
    intl,
    isManagerialTableAndTimePassed,
    createBooking,
    cancel,
    handleMoveClick,
    setTimestamp,
    nextStatus,
    handleStatusChange,
    recovery,
    handlePrint,
    isPrintDisabled: isDisabled,
    show,
    hide,
    popup,
    setWasOpen,
    force,
    goNextStatus,
    resetForce,
    isFloorplan: pathname.includes("/hall-scheme"),
    managerialFormBookingState
  };
};

export default useBookingActions;